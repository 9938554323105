import React from 'react';
import {
  TableCell,
} from '@mui/material';
import PropTypes from 'prop-types';

function StyledTableCell(props) {
  const { children, size } = props;
  return (
    <TableCell
      className="table-cell"
      align="center"
      sx={{
        backgroundColor: '#c5c7c9',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        width: size,
      }}
    >
      {children}
    </TableCell>
  );
}

StyledTableCell.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
};

StyledTableCell.defaultProps = {
  size: '',
};

export default StyledTableCell;
