import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import 'ace-builds';
import 'ace-builds/webpack-resolver';
import AceEditor from 'react-ace';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import PersonOffIcon from '@mui/icons-material/PersonOff';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import TenantInfoBar from '../TenantInfoBar';
import WorkflowPreview from '../WorkflowPreview';
import Alert from './Alert';
import ConfirmModal from './ConfirmModal';
import CreateWorkflowUsersModal from './CreateWorkflowUsersModal';
import WorkflowErrorModal from './WorkflowErrorModal';

import { getUserData } from '../../utils/requests';

const CreateWorkflowModal = observer((props) => {
  const {
    workflowStore, open, onClose, onSubmit, actionType, tenantStore, userStore,
  } = props;

  const [showActionPreview, setShowActionPreview] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCreateUsersModal, setShowCreateUsersModal] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, data: [{}] });

  const [alertState, setAlertState] = React.useState({
    open: false,
    text: 'placeholder',
    type: 'success',
  });

  const [numInputs, setNumInputs] = useState(0);
  const [tableToShow, setTableToShow] = useState('inputs');
  const [userProblems, setUserProblems] = useState(false);
  const [numUserProblems, setNumUserProblems] = useState(0);
  const [numUsers, setNumUsers] = useState(0);
  const [usedUsers, setUsedUsers] = useState({});
  const [userStates] = useState({
    validUser: 'validUser',
    needsPassword: 'needsPassword',
    invalidUser: 'invalidUser',
  });

  const handleTableChange = (event, value) => {
    setTableToShow(value);
  };

  const refreshData = () => {
    getUserData().then((response) => {
      if (response.status === 200) {
        userStore.setUsers(response.data);
      }
    });
  };

  useEffect(() => {
    if (workflowStore.newWorkflowParams) {
      const userData = workflowStore.newWorkflowParams.usersObj;

      Object.keys(userData).forEach((username) => {
        if (userData[username].needsPass) {
          userData[username].state = userStates.needsPassword;
        } else {
          userData[username].state = userStates.invalidUser;
        }
      });

      userStore.users.forEach((user) => {
        const { username } = user;
        if (Object.prototype.hasOwnProperty.call(userData, user.username)) {
          if (userData[username].needsPass && user.noPass) {
            userData[username].state = userStates.needsPassword;
          } else {
            userData[username].state = userStates.validUser;
          }
        }
      });

      if (Object.prototype.hasOwnProperty.call(userData, '$ADMIN$')) {
        userData.$ADMIN$.state = userStates.validUser;
      }

      let newUserProblems = false;
      const keys = Object.keys(userData);
      let countUserProblems = 0;
      for (let i = 0; i < keys.length; i += 1) {
        const username = keys[i];
        if (userData[username].state !== userStates.validUser) {
          countUserProblems += 1;
        }
      }
      newUserProblems = (countUserProblems > 0);
      setUserProblems(newUserProblems);
      setNumUserProblems(countUserProblems);

      setUsedUsers(userData);
    }
  }, [
    userStates.invalidUser,
    userStates.needsPassword,
    userStates.validUser,
    userStore.users,
    workflowStore.newWorkflowParams,
  ]);

  useEffect(() => {
    setNumUsers(Object.keys(usedUsers).length);
  }, [usedUsers]);

  useEffect(() => {
    setNumInputs(workflowStore.newWorkflowParams?.inputs?.length);
  }, [workflowStore.newWorkflowParams?.inputs?.length]);

  const userStateData = (username) => {
    let returnValue = {};
    const user = usedUsers[username];
    switch (user?.state) {
      case undefined:
        returnValue = { icon: '', msg: '' };
        break;
      case userStates.validUser:
        returnValue = {
          icon: (<VerifiedUserIcon />),
          msg: 'Valid user',
          color: 'auto',
        };
        break;
      case userStates.needsPassword:
        returnValue = {
          icon: (<VpnKeyIcon sx={{ color: 'red' }} />),
          msg: `User needs password for ${user?.type}`,
          color: 'red',
        };
        break;
      case userStates.invalidUser:
      default:
        returnValue = {
          icon: (<PersonOffIcon sx={{ color: 'red' }} />),
          msg: 'User not found',
          color: 'red',
        };
        break;
    }
    return returnValue;
  };

  // useEffect(() => {
  //   workflowStore.setNewWorkflow(defaultValues);
  // }, [defaultValues, workflowStore]);

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'workflowName':
        workflowStore.setNewWorkflow({ name: e.target.value });
        break;
      case 'workflowVersion':
        workflowStore.setNewWorkflow({ version: e.target.value });
        break;
      case 'workflowActive':
        workflowStore.setNewWorkflow({ isActive: e.target.checked });
        break;
      case 'workflowActions':
        workflowStore.setNewWorkflow({ actions: e.target.value });
        break;
      default:
        break;
    }
  };

  const resetValues = () => {
    // workflowStore.setNewWorkflow(defaultValues());
  };

  const handleSubmit = async () => {
    setShowConfirmModal(false);
    const res = await onSubmit(actionType);
    if (Object.keys(res).length !== 0) {
      setErrorModal({ show: true, data: res });
    }
  };

  const cancel = () => {
    onClose('New');
    resetValues();
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth disableEscapeKeyDown>
      <WorkflowErrorModal
        open={errorModal.show}
        onClose={() => setErrorModal({ show: false, data: [{}] })}
        data={errorModal.data}
      />
      <Alert
        open={alertState.open}
        onClose={() => setAlertState({ ...alertState, open: false })}
        text={alertState.text}
        type={alertState.type}
      />
      <ConfirmModal
        text={`Are you sure you want to ${actionType === 'New' ? 'create' : 'edit'} workflow: ${workflowStore.newWorkflow?.name} - ${workflowStore.newWorkflow?.version}`}
        open={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={handleSubmit}
      />
      <CreateWorkflowUsersModal
        usedUsers={usedUsers}
        userStore={userStore}
        userStates={userStates}
        refreshUsers={refreshData}
        open={showCreateUsersModal}
        onCancel={() => setShowCreateUsersModal(false)}
      />
      <DialogTitle style={{ borderBottom: '1px solid' }}>
        {`${(actionType === 'New') ? 'Create' : 'Edit'} Workflow`}
      </DialogTitle>
      <TenantInfoBar tenantStore={tenantStore} />
      <DialogContent>
        <div style={{ display: 'flex', height: '60vh' }}>
          <div style={{ flex: 1, padding: 10, overflow: 'auto' }}>
            <div className="panel-title"> Workflow Information </div>
            <div>
              <TextField
                disabled={Boolean(actionType === 'Edit')}
                label="Workflow Name"
                variant="outlined"
                style={{ width: '100%', margin: 5 }}
                name="workflowName"
                value={workflowStore.newWorkflow?.name}
                onChange={handleChange}
                inputProps={{ maxLength: 64 }}
              />
            </div>
            <div>
              <TextField
                disabled={Boolean(actionType === 'Edit')}
                label="Version"
                variant="outlined"
                style={{ width: '100%', margin: 5 }}
                name="workflowVersion"
                value={workflowStore.newWorkflow?.version}
                onChange={handleChange}
                inputProps={{ maxLength: 64 }}
              />
            </div>
            <div>
              <FormGroup row style={{ margin: '10px 10px 0 10px' }}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={Boolean(workflowStore.newWorkflow?.isActive)}
                      onChange={handleChange}
                      name="workflowActive"
                      color="primary"
                    />
                  )}
                  label="Is Active"
                />
              </FormGroup>
            </div>
            <Paper
              variant="outlined"
              sx={{
                padding: '10px',
              }}
            >
              <Tabs
                value={tableToShow}
                onChange={handleTableChange}
              >
                <Tab
                  label={`Inputs (${workflowStore.newWorkflowParams?.inputs?.length})`}
                  value="inputs"
                  sx={{
                    width: '50%',
                  }}
                />
                <Tab
                  label={
                    `Users (${Object.keys(usedUsers).length})${(userProblems) ? '*' : ''}`
                  }
                  value="users"
                  sx={{
                    width: '50%',
                    color: (userProblems) ? 'red !important' : 'black',
                  }}
                />
              </Tabs>
              {(workflowStore.newWorkflowParams[tableToShow]?.length !== 0) ? (
                <Box>
                  { (tableToShow === 'inputs')
                    ? (
                      <List align="center" dense>
                        {workflowStore.newWorkflowParams?.inputs?.map((input, index) => (
                          <ListItem
                            key={input}
                            divider={(numInputs > 1) && (index < (numInputs - 1))}
                          >
                            <ListItemIcon align="center" />
                            <ListItemText primary={input} />
                          </ListItem>
                        ))}
                      </List>
                    )
                    : (
                      <List align="center" dense>
                        <ListItem key="ButtonListItem">
                          {(userProblems) ? (
                            <Box sx={{ width: '100%' }} align="center">
                              <Button
                                variant="outlined"
                                color="error"
                                sx={{ marginTop: '15px', marginBottom: '15px', width: '60%' }}
                                onClick={() => setShowCreateUsersModal(true)}
                              >
                                Fix Users
                              </Button>
                            </Box>
                          ) : ''}
                        </ListItem>
                        {
                          Object.keys(usedUsers).map((user, index) => {
                            const stateData = userStateData(user);
                            return (
                              <ListItem
                                key={user}
                                divider={(numUsers > 1) && (index < (numUsers - 1))}
                              >
                                <ListItemIcon align="center">
                                  <Tooltip title={stateData.msg}>
                                    {stateData.icon}
                                  </Tooltip>
                                </ListItemIcon>
                                <ListItemText sx={{ color: stateData.color }} primary={user} />
                              </ListItem>
                            );
                          })
                        }
                      </List>
                    )}
                </Box>
              ) : (
                <Box sx={{ padding: '20px 5px 20px 5px', color: '#404040' }}>
                  {`Any ${tableToShow} referenced by your workflow will appear here.`}
                </Box>
              )}
            </Paper>

          </div>
          <div style={{ flex: 2, padding: 10, overflow: 'auto' }}>
            <div className="panel-title"> Workflow Actions Array </div>
            <div style={{ margin: '10px 0' }}>
              <Button color="primary" variant="contained" onClick={() => setShowActionPreview(!showActionPreview)}>
                {showActionPreview ? 'Show Editor' : 'Show Preview'}
              </Button>
            </div>
            {showActionPreview
              ? <WorkflowPreview actions={JSON.parse(workflowStore.newWorkflow?.actions)} /> : (
                <AceEditor
                  mode="json"
                  theme="github"
                  width="100%"
                  height="85%"
                  onChange={(value) => handleChange({ target: { name: 'workflowActions', value } })}
                  value={workflowStore.newWorkflow?.actions}
                />
              )}

          </div>

        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex', justifyContent: 'flex-start', borderTop: '1px solid', padding: 16, marginTop: 20,
        }}
      >
        <Button disabled={userProblems} color="primary" variant="contained" onClick={() => setShowConfirmModal(true)}>
          Save
        </Button>
        <Button color="secondary" variant="contained" onClick={cancel}>
          Cancel
        </Button>
        {(userProblems) ? (
          <Typography sx={{ color: 'red', paddingLeft: '20px' }}>
            {`*Fix your (${numUserProblems}) user problems before saving`}
          </Typography>
        ) : ''}
      </DialogActions>
    </Dialog>
  );
});

export default CreateWorkflowModal;
