import {
  makeObservable, observable, action,
} from 'mobx';

class User {
  username = '';

  description = '';

  dateLastModified = '';

  lastModifiedUser = '';

  isActive = null;

  verification = null;

  noPass = false;

  constructor(data) {
    makeObservable(this, {
      username: observable,
      description: observable,
      dateLastModified: observable,
      lastModifiedUser: observable,
      isActive: observable,
      verification: observable,
      setVerification: action,
      noPass: observable,
    });
    this.username = data.username;
    this.description = data.description;
    this.dateLastModified = data.dateLastModified;
    this.lastModifiedUser = data.lastModifiedUser;
    this.isActive = data.isActive;
    this.noPass = data.noPass;
  }

  setVerification(state = false, time = new Date()) {
    this.verification = { success: state, time };
  }
}

export default User;
