import {
  makeObservable, observable, action,
} from 'mobx';
import Tenant from './baseClasses/tenant';
import { getTenantData } from '../utils/requests';

class TenantStore {
  tenants = [];

  currTenant = null;

  constructor() {
    makeObservable(this, {
      tenants: observable,
      currTenant: observable,
      setTenants: action,
      setTenant: action,
      setCurrTenant: action,
    });
  }

  async getValues() {
    if (!this.tenants.length) {
      const res = await getTenantData();
      for (let i = 0; i < res.data.length; i += 1) {
        let tenantIdDateIndex = window.localStorage.getItem('tenantIdDateIndex') || '{}';
        tenantIdDateIndex = JSON.parse(tenantIdDateIndex);
        res.data[i].dateLastAccessed = tenantIdDateIndex[res.data[i].tenantId] || '0';
      }
      if (res.status === 200) {
        if (!res.data.name) {
          this.setTenants(res.data);
        } else {
          this.setTenant(res.data);
        }
      }
    }
  }

  setTenants(data) {
    this.tenants = data.map((tenant) => new Tenant(tenant));
  }

  setTenant(data) {
    this.tenants = [data];
  }

  setCurrTenant(tenantId) {
    this.tenants.forEach((tenant) => {
      if (tenant.tenantId === tenantId) {
        this.currTenant = tenant;
      }
    });
  }
}

const tenantStore = new TenantStore();

export default tenantStore;
