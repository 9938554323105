import {
  makeObservable, observable, action,
} from 'mobx';
import User from './baseClasses/user';

const blankUser = {
  username: '',
  description: '',
  isActive: 1,
  password: '',
};

class UserStore {
  users = [];

  currUser = blankUser;

  constructor() {
    makeObservable(this, {
      users: observable,
      currUser: observable,
      setUsers: action,
      setCurrUser: action,
      clearCurrUser: action,
    });
  }

  setUsers(data) {
    this.users = data.map((usr) => new User(usr));
  }

  setCurrUser(usr) {
    this.currUser = usr;
  }

  clearCurrUser() {
    this.currUser = blankUser;
  }
}

const userStore = new UserStore();

export default userStore;
