import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import { observer } from 'mobx-react-lite';
import TenantInfoBar from '../TenantInfoBar';
import ConfirmModal from './ConfirmModal';

const ModifyUserModal = observer((props) => {
  const {
    tenantStore, open, user, actionType, onClose, onSubmit,
  } = props;
  const [newUser, setNewUser] = useState(user);
  const [editPassword, setEditPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmText, setShowConfirmText] = useState('');

  useEffect(() => {
    setNewUser(user);
    // need to disable the warning or else the newUser state will get reset
    // every rerender when creating a new user
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNewUser, user]);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose(actionType);
      setEditPassword(false);
    }
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'descriptionInput':
        setNewUser({ ...newUser, description: e.target.value });
        break;
      case 'usernameInput':
        setNewUser({ ...newUser, username: e.target.value });
        break;
      case 'inactiveInput':
        setNewUser({ ...newUser, isActive: e.target.checked });
        break;
      case 'passwordInput':
        setNewUser({ ...newUser, password: e.target.value });
        break;
      case 'togglePassword':
        setEditPassword(!editPassword);
        setNewUser({ ...newUser, password: '' });
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    setShowConfirm(false);
    let success = true;
    if (actionType === 'New') {
      success = await onSubmit(actionType, {
        username: newUser.username,
        password: newUser.password,
        description: newUser.description || '',
        isActive: newUser.isActive || 0,
      });
    } if (actionType === 'Edit') {
      const payload = {
        username: user.username,
        password: newUser.password,
        description: newUser.description || '',
        isActive: newUser.isActive || 0,
        changePassword: false,
      };

      if (editPassword) payload.changePassword = true;
      success = await onSubmit(actionType, payload);
    }
    if (success) {
      handleClose();
    }
  };

  return (
    <div>
      <ConfirmModal
        open={showConfirm}
        text={showConfirmText}
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => handleSubmit()}
      />
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth="sm">
        <DialogTitle style={{ borderBottom: '1px solid' }}>
          {/* we want to use add instead of new since we aren't actually creating a new user, we
          are using an existing user in brighspace to add to automat2n */}
          {actionType === 'New' ? 'Add ' : 'Edit '}
          Workflow User
        </DialogTitle>
        <TenantInfoBar tenantStore={tenantStore} />
        {user && (
          <DialogContent sx={{ minWidth: 550, minHeight: 300 }}>
            {(actionType === 'New')
              && (
                <Typography>
                  Specify the username and password for an
                  {' '}
                  <b>existing Brightspace user</b>
                  {' '}
                  to add as a workflow user
                </Typography>
              )}
            <p style={{ fontWeight: 'bold' }}>
              Username:
            </p>
            {(actionType === 'New')
              ? (
                <TextField
                  variant="outlined"
                  style={{ width: '100%' }}
                  onChange={(e) => handleChange(e)}
                  name="usernameInput"
                />
              )
              : (
                <Typography
                  style={{ width: '100%', padding: '0' }}
                >
                  {user.username}
                </Typography>
              )}

            <p style={{ fontWeight: 'bold' }}>
              Description:
            </p>

            <TextField
              multiline
              rows={4}
              variant="outlined"
              onChange={(e) => handleChange(e)}
              value={newUser.description}
              style={{ width: '100%' }}
              name="descriptionInput"
              inputProps={{ maxLength: 200 }}
            />

            <FormGroup row style={{ margin: '10px 10px 0 10px' }}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={Boolean(newUser.isActive) || false}
                    onChange={(e) => handleChange(e)}
                    name="inactiveInput"
                    color="primary"
                  />
                )}
                label="Is Active"
              />
            </FormGroup>

            {actionType === 'New' ? (
              <div>
                <p style={{ fontWeight: 'bold' }}>
                  Password:
                </p>
                <TextField
                  variant="outlined"
                  style={{ width: '100%' }}
                  onChange={(e) => handleChange(e)}
                  name="passwordInput"
                  type="password"
                />
              </div>

            ) : (
              <div>
                <FormGroup row style={{ margin: '10px 10px 0 10px' }}>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={editPassword}
                        onChange={(e) => handleChange(e)}
                        name="togglePassword"
                        color="primary"
                      />
                    )}
                    label="Change Password"
                  />
                </FormGroup>
                <TextField
                  disabled={!editPassword}
                  variant="outlined"
                  onChange={(e) => handleChange(e)}
                  style={{ width: '75%', marginLeft: 40 }}
                  name="passwordInput"
                  type="password"
                />
              </div>

            )}

          </DialogContent>
        )}

        <DialogActions style={{
          display: 'flex', justifyContent: 'space-between', borderTop: '1px solid', marginTop: 20, padding: 16,
        }}
        >
          <div>
            <Button
              variant="contained"
              onClick={() => {
                setShowConfirm(true);
                if (actionType === 'Edit') setShowConfirmText(`Are you sure you want to edit user: ${newUser.username}?`);
                else if (actionType === 'New') setShowConfirmText(`Are you sure you want to add user: ${newUser.username}?`);
              }}
              color="primary"
              autoFocus
              style={{ margin: '0 5px 0 5px' }}
            >
              Save
            </Button>
            <Button variant="contained" onClick={handleClose} color="secondary" style={{ margin: '0 5px 0 5px' }}>
              Cancel
            </Button>
          </div>

        </DialogActions>
      </Dialog>
    </div>

  );
});

ModifyUserModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tenantStore: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  actionType: PropTypes.string.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    description: PropTypes.string,
    isActive: PropTypes.number,
  }),
};

ModifyUserModal.defaultProps = {
  user: null,
};

export default ModifyUserModal;
