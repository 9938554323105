import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { beautifyDate } from '../../utils/helpers';
import ConfirmModal from './ConfirmModal';

import TenantInfoBar from '../TenantInfoBar';
import LoadingSpinner from './LoadingSpinner';

function ViewUserModal(props) {
  const {
    tenantStore, open, user, onSubmit, onClose,
  } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      if (event === 'open edit') {
        onClose('View and open Edit');
      } else if (event === 'delete') {
        setShowConfirm(false);
        onSubmit('delete', user.username);
        onClose('View');
      } else onClose('View');
    }
  };

  const verifyUser = async () => {
    setShowLoadingSpinner(true);
    await onSubmit('Verify', user.username);
    setShowLoadingSpinner(false);
  };
  return (
    <div>
      <ConfirmModal open={showConfirm} text={`Are you sure you want to delete user: ${user?.username}?`} onCancel={() => setShowConfirm(false)} onConfirm={() => handleClose('delete')} />
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth="sm">
        <LoadingSpinner show={showLoadingSpinner} />

        <DialogTitle style={{ borderBottom: '1px solid' }}>Workflow User</DialogTitle>
        <TenantInfoBar tenantStore={tenantStore} />
        {user && (
        <DialogContent sx={{ minWidth: 550, minHeight: 300 }}>

          <p style={{ fontWeight: 'bold' }}>
            Username:
          </p>
          <p>
            {user.username}
          </p>

          <p style={{ fontWeight: 'bold' }}>
            Description:
          </p>
          <p style={{ overflowY: 'scroll', maxHeight: 100, wordBreak: 'break-all' }}>
            {user.description}
          </p>

          <Chip label={user.isActive ? 'User is Active' : 'User is Inactive'} style={{ backgroundColor: user.isActive ? 'green' : 'red', color: 'white' }} />
          {user.verification && (
          <div>
            <p style={{ fontWeight: 'bold' }}>
              Verification:
            </p>
            <p>
              {user.verification.success ? <CheckIcon style={{ verticalAlign: 'middle' }} /> : <CloseIcon style={{ verticalAlign: 'middle' }} />}
              {' '}
              {user.verification.success ? 'Success' : 'Failure'}
              {' '}
              (
              {beautifyDate(user.verification.time, 'short')}
              )

            </p>
          </div>
          )}

        </DialogContent>
        )}

        <DialogActions style={{
          display: 'flex', justifyContent: 'space-between', borderTop: '1px solid', marginTop: 20, padding: 16,
        }}
        >
          <div>
            <Button variant="contained" onClick={handleClose} color="primary" autoFocus style={{ margin: '0 5px 0 5px' }}>
              Close
            </Button>
            <Button variant="contained" onClick={verifyUser} color="secondary" style={{ margin: '0 5px 0 5px' }}>
              Verify User
            </Button>
            <Button variant="contained" onClick={() => handleClose('open edit')} color="secondary" style={{ margin: '0 5px 0 5px' }}>
              Edit
            </Button>

          </div>
          <div>
            <Button variant="contained" onClick={() => setShowConfirm(true)} style={{ backgroundColor: 'red', color: 'white' }}>
              Delete
            </Button>
          </div>

        </DialogActions>
      </Dialog>
    </div>

  );
}

ViewUserModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tenantStore: PropTypes.object.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    description: PropTypes.string,
    isActive: PropTypes.number,
    verification: PropTypes.shape({
      success: PropTypes.bool,
      time: PropTypes.string,
    }),
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ViewUserModal.defaultProps = {
  user: null,
};
export default ViewUserModal;
