/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import PersonOffIcon from '@mui/icons-material/PersonOff';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import 'ace-builds';
import 'ace-builds/webpack-resolver';
import AceEditor from 'react-ace';
import { Link } from 'react-router-dom';

import TenantInfoBar from '../TenantInfoBar';
import WorkflowPreview from '../WorkflowPreview';
import Alert from './Alert';
import ConfirmModal from './ConfirmModal';
import CreateWorkflowUsersModal from './CreateWorkflowUsersModal';

import { getUserData } from '../../utils/requests';

const ViewWorkFlowModal = observer((props) => {
  const {
    tenantStore, workflowStore, userStore, open, onClose, onSubmit,
  } = props;
  const [showActionPreview, setShowActionPreview] = useState(true);
  const [showCreateUsersModal, setShowCreateUsersModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [alertState, setAlertState] = React.useState({ open: false, text: 'placeholder', type: 'success' });

  const [editorText, setEditorText] = useState('');
  const [tableToShow, setTableToShow] = useState('inputs');
  const [numInputs, setNumInputs] = useState(0);
  const [userProblems, setUserProblems] = useState(false);
  const [numUsers, setNumUsers] = useState(0);
  const [usedUsers, setUsedUsers] = useState({});
  const [userStates] = useState({
    validUser: 'validUser',
    needsPassword: 'needsPass',
    invalidUser: 'invalidUser',
  });

  const refreshData = () => {
    getUserData().then((response) => {
      if (response.status === 200) {
        userStore.setUsers(response.data);
      }
    });
  };

  const handleTableChange = (event, value) => {
    setTableToShow(value);
  };

  useEffect(() => {
    if (workflowStore.currentWorkflowVersion?.actions) {
      setEditorText(JSON.stringify(workflowStore.currentWorkflowVersion?.actions, null, 2));
    } else {
      setEditorText('');
    }

    if (workflowStore.currentWorkflowVersion?.params?.users) {
      const userData = workflowStore.currentWorkflowVersion.params.users;

      Object.keys(userData).forEach((username) => {
        if (userData[username].needsPass) {
          userData[username].state = userStates.needsPassword;
        } else {
          userData[username].state = userStates.invalidUser;
        }
      });

      userStore.users.forEach((user) => {
        const { username } = user;
        if (Object.prototype.hasOwnProperty.call(userData, user.username)) {
          if (userData[username].needsPass && user.noPass) {
            userData[username].state = userStates.needsPassword;
          } else {
            userData[username].state = userStates.validUser;
          }
        }
      });
      if (Object.prototype.hasOwnProperty.call(userData, '$ADMIN$')) {
        userData.$ADMIN$.state = userStates.validUser;
      }

      let newUserProblems = false;
      const keys = Object.keys(userData);
      for (let i = 0; i < keys.length; i += 1) {
        const username = keys[i];
        if (userData[username].state !== userStates.validUser) {
          newUserProblems = true;
          break;
        }
      }
      setUserProblems(newUserProblems);

      setUsedUsers(userData);
    }
  }, [userStates, userStore.users, workflowStore.currentWorkflowVersion]);

  useEffect(() => {
    setNumUsers(Object.keys(usedUsers).length);
  }, [usedUsers]);

  useEffect(() => {
    setNumInputs(workflowStore?.currentWorkflowVersion?.params?.inputs?.length);
  }, [workflowStore?.currentWorkflowVersion?.params?.inputs]);

  const userStateData = (username) => {
    let returnValue = {};
    const user = usedUsers[username];
    switch (user?.state) {
      case undefined:
        returnValue = { icon: '', msg: '' };
        break;
      case userStates.validUser:
        returnValue = {
          icon: (<VerifiedUserIcon />),
          msg: 'Valid user',
          color: 'auto',
        };
        break;
      case userStates.needsPassword:
        returnValue = {
          icon: (<VpnKeyIcon sx={{ color: 'red' }} />),
          msg: `User needs password for ${user?.type}`,
          color: 'red',
        };
        break;
      case userStates.invalidUser:
      default:
        returnValue = {
          icon: (<PersonOffIcon sx={{ color: 'red' }} />),
          msg: 'User not found',
          color: 'red',
        };
        break;
    }
    return returnValue;
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth disableEscapeKeyDown>
      <ConfirmModal
        text={`Are you sure you want to delete workflow: ${workflowStore.currentWorkflow?.name} - ${workflowStore.currentWorkflowVersion?.version}`}
        open={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => { setShowConfirmModal(false); onSubmit('Delete'); }}
      />
      <Alert
        open={alertState.open}
        onClose={() => setAlertState({ ...alertState, open: false })}
        text={alertState.text}
        type={alertState.type}
      />
      <CreateWorkflowUsersModal
        usedUsers={usedUsers}
        userStore={userStore}
        userStates={userStates}
        refreshUsers={refreshData}
        open={showCreateUsersModal}
        onCancel={() => setShowCreateUsersModal(false)}
      />
      <DialogTitle style={{ borderBottom: '1px solid' }}>
        View Workflow
      </DialogTitle>
      <TenantInfoBar tenantStore={tenantStore} />
      <DialogContent>
        <div style={{ display: 'flex', height: '60vh' }}>
          <div style={{ flex: 1, padding: 10 }}>
            <div className="panel-title"> Workflow Information </div>

            <div style={{ margin: '10px 0' }}>
              <div style={{ fontWeight: 'bold' }}>
                Workflow Name:
              </div>
              <div>
                {workflowStore.currentWorkflow?.name}
              </div>
            </div>
            <div style={{ margin: '10px 0' }}>
              <div style={{ fontWeight: 'bold' }}>
                Version:
              </div>
              <div style={{ wordBreak: 'break-all' }}>
                {workflowStore.currentWorkflowVersion?.version}
              </div>
            </div>

            <div style={{ margin: '10px 0' }}>
              <div style={{ margin: '5px 0' }}>
                <Chip
                  label={workflowStore.currentWorkflowVersion?.isActive ? 'Workflow is Active' : 'Workflow is Inactive'}
                  style={workflowStore.currentWorkflowVersion?.isActive
                    ? { backgroundColor: 'green', color: 'white', minWidth: '33%' } : { backgroundColor: '#e0e0e0', color: 'black' }}
                />
              </div>
              <div style={{ margin: '5px 0' }}>
                {workflowStore.currentWorkflowVersion?.isDefault === 1 && (
                <Chip
                  label="Default Version"
                  color="primary"
                  style={{ minWidth: '33%' }}
                />
                )}
              </div>

            </div>

            <Paper
              variant="outlined"
              sx={{
                padding: '10px',
              }}
            >
              <Tabs
                value={tableToShow}
                onChange={handleTableChange}
              >
                <Tab
                  label={`Inputs (${numInputs})`}
                  value="inputs"
                  sx={{
                    width: '50%',
                  }}
                />
                <Tab
                  label={
                    `Users (${numUsers})${(userProblems) ? '*' : ''}`
                  }
                  value="users"
                  sx={{
                    width: '50%',
                    color: (userProblems) ? 'red !important' : 'black',
                  }}
                />
              </Tabs>
              {workflowStore.currentWorkflowVersion?.params[tableToShow]?.length !== 0 ? (
                <Box>
                  { (tableToShow === 'inputs')
                    ? (
                      <List align="center" dense>
                        {workflowStore.currentWorkflowVersion?.params?.inputs?.map((input, index) => (
                          <ListItem
                            key={input}
                            divider={(numInputs > 1) && (index < (numInputs - 1))}
                          >
                            <ListItemIcon align="center" />
                            <ListItemText primary={input} />
                          </ListItem>
                        ))}
                      </List>
                    )
                    : (
                      <List align="center" dense>
                        <ListItem key="ButtonListItem">
                          {(userProblems) ? (
                            <Box sx={{ width: '100%' }} align="center">
                              <Button
                                variant="outlined"
                                color="error"
                                sx={{ marginTop: '15px', marginBottom: '15px', width: '60%' }}
                                onClick={() => setShowCreateUsersModal(true)}
                              >
                                Fix Users
                              </Button>
                            </Box>
                          ) : ''}
                        </ListItem>
                        {
                          Object.keys(usedUsers).map((user, index) => {
                            const stateData = userStateData(user);
                            return (
                              <ListItem
                                key={user}
                                divider={(numUsers > 1) && (index < (numUsers - 1))}
                              >
                                <ListItemIcon align="center">
                                  <Tooltip title={stateData.msg}>
                                    {stateData.icon}
                                  </Tooltip>
                                </ListItemIcon>
                                <ListItemText sx={{ color: stateData.color }} primary={user} />
                              </ListItem>
                            );
                          })
                        }
                      </List>

                    )}
                </Box>
              ) : (
                <Box sx={{ padding: '20px 5px 20px 5px', color: '#404040' }}>
                  {`Any ${tableToShow} referenced by your workflow will appear here.`}
                </Box>
              )}
            </Paper>

          </div>
          <div style={
            {
              flex: 2,
              padding: 10,
              height: '55vh',
            }
          }
          >
            <div className="panel-title"> Workflow Actions </div>
            <div style={{ margin: '10px 0' }}>
              <Button color="primary" variant="contained" onClick={() => setShowActionPreview(!showActionPreview)}>
                {showActionPreview ? 'Show Editor' : 'Show Preview'}
              </Button>
            </div>
            {showActionPreview
              ? <WorkflowPreview actions={workflowStore.currentWorkflowVersion?.actions} /> : (
                <AceEditor
                  mode="json"
                  theme="github"
                  width="100%"
                  height="85%"
                  readOnly
                  value={editorText}
                />
              )}

          </div>

        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex', justifyContent: 'space-between', borderTop: '1px solid', padding: 16, marginTop: 20,
        }}
      >
        <div>
          <Button color="primary" variant="contained" onClick={() => onClose('View')} style={{ margin: 5 }}>
            Close
          </Button>
          <Link to={`/run/${tenantStore?.currTenant?.tenantId}`} style={{ textDecoration: 'none' }}>
            <Button color="secondary" variant="contained" style={{ margin: 5 }}>
              Run
            </Button>
          </Link>
          <Button color="secondary" variant="contained" onClick={() => onClose('View and Edit')} style={{ margin: 5 }}>
            Edit
          </Button>
          <Button color="secondary" variant="contained" onClick={() => onClose('View and Copy')} style={{ margin: 5 }}>
            Copy
          </Button>
          <Button
            disabled={!workflowStore.currentWorkflowVersion?.isActive}
            color="secondary"
            variant="contained"
            onClick={() => onSubmit('Default', { isDefault: !workflowStore.currentWorkflowVersion.isDefault })}
            style={{ margin: 5 }}
          >
            {workflowStore.currentWorkflowVersion?.isDefault ? 'Unset as Default' : 'Set as Default'}
          </Button>

          <Button
            disabled={Boolean(workflowStore.currentWorkflowVersion?.isDefault)}
            color="secondary"
            variant="contained"
            onClick={() => onSubmit('Active', { isActive: !workflowStore.currentWorkflowVersion.isActive })}
            style={{ margin: 5 }}
          >
            {workflowStore.currentWorkflowVersion?.isActive ? 'Deactivate' : 'Activate'}
          </Button>
        </div>

        <div>
          <Button
            disabled={Boolean(workflowStore.currentWorkflowVersion?.isDefault)}
            color="secondary"
            variant="contained"
            onClick={() => setShowConfirmModal(true)}
            style={{ margin: 5 }}
          >
            Delete
          </Button>
        </div>

      </DialogActions>
    </Dialog>
  );
});

export default ViewWorkFlowModal;
