import React, { useState } from 'react';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import ViewRunCsvModal from './modals/ViewRunCsvModal';

const RunCsv = observer(() => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        style={{
          padding: '10px', margin: '5px', height: '40px', whiteSpace: 'nowrap',
        }}
        onClick={() => setShowModal(true)}
      >
        Run CSV
      </Button>
      <ViewRunCsvModal
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
});

export default RunCsv;
