import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import {
  AppBar,
  Box,
  Container,
  Grid,
  Toolbar,
  Typography,
} from '@mui/material';

import ImportExport from './ImportExport';
import RunCsv from './RunCsv';
import TenantInfoBar from './TenantInfoBar';
import { getTenantId } from '../utils/helpers';

const Navbar = observer((props) => {
  const {
    page, tenantStore, userStore, workflowStore,
  } = props;

  const stateGetTenantId = useCallback(() => getTenantId(), []);
  const [stateTenantId, setStateTenantId] = useState('');

  useEffect(() => setStateTenantId(stateGetTenantId()), [stateGetTenantId]);

  return (
    <>
      <Box sx={{ backgroundColor: '#2c3e50' }}>
        <Container sx={{ display: 'flex' }}>
          <AppBar position="relative" elevation={0} sx={{ backgroundColor: '#2c3e50', display: 'flex' }}>
            <Toolbar sx={{ display: 'flex' }}>
              <Grid container alignItems="center" sx={{ display: 'flex' }}>
                <Grid item sx={{ height: '75px' }}>
                  <Link className="Nav-Brand Nav-Item Link" to={`/login/${process.env.REACT_APP_ROOT_TENANT_ID}/?auto=1`}>
                    <Typography component="h1" variant="h4">Automa2n</Typography>
                  </Link>
                </Grid>
                <Grid item sx={{ height: '75px' }}>
                  <Link to={`/manage/${stateTenantId}`} className={`Nav-Item Link ${page === 'manage' ? 'Nav-Current' : ''}`}>
                    Manage
                  </Link>
                </Grid>
                <Grid item sx={{ height: '75px' }}>
                  <Link to={`/run/${stateTenantId}`} className={`Nav-Item Link ${page === 'run' ? 'Nav-Current' : ''}`}>
                    Run
                  </Link>
                </Grid>
                <Grid item sx={{ height: '75px' }}>
                  <Link to={`/help/${stateTenantId}`} className={`Nav-Item Link ${page === 'help' ? 'Nav-Current' : ''}`}>
                    Help
                  </Link>
                </Grid>
                <Grid item sx={{ height: '75px' }}>
                  <Link to={`/logs/${stateTenantId}`} className={`Nav-Item Link ${page === 'logs' ? 'Nav-Current' : ''}`}>
                    Logs
                  </Link>
                </Grid>
                {(page === 'manage') && (
                  <Grid item sx={{ height: '75px', alignItems: 'center', display: 'flex' }}>
                    <Box>
                      <ImportExport className="right" tenantId={stateTenantId} userStore={userStore} workflowStore={workflowStore} />
                      <RunCsv />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Toolbar>
          </AppBar>
        </Container>
      </Box>
      <TenantInfoBar tenantStore={tenantStore} />
    </>
  );
});

export default Navbar;
