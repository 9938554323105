import './App.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { observer } from 'mobx-react-lite';

import Login from './components/Login';
import Manage from './components/Manage';
import Callback from './components/Callback';
import Help from './components/Help';
import Run from './components/Run';
import TenantList from './components/TenantList';
import AllWorkflowRunLogs from './components/AllWorkflowRunLogs';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#eeeeee',
      darker: '#bdbdbd',
    },
  },
  typography: {
    fontFamily: '"Lato-Regular", "Arial", "sans-serif"',
    button: {
      textTransform: 'none',
    },
  },
});

const App = observer(({ store }) => {
  if (!('process' in window)) {
    window.process = {};
  }

  useEffect(() => {
    async function updateTitle() {
      let title = 'Automa2n';
      // with current tenant set title
      if (store.tenantStore.currTenant) {
        title = `${store.tenantStore.currTenant.name} | Automa2n`;
      }

      switch (process.env.REACT_APP_ENV) {
        case 'development':
          title += ' (DEV)';
          break;
        case 'local':
          title += ' (LOCAL)';
          break;
        default:
          break;
      }

      document.title = title;
    }
    updateTitle();
  }, [store.tenantStore.currTenant]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/logs/:tenantId">
            <AllWorkflowRunLogs tenantStore={store.tenantStore} />
          </Route>

          <Route path="/help/:tenantId">
            <Help tenantStore={store.tenantStore} />
          </Route>
          <Route path="/run/:tenantId">
            <Run workflowStore={store.workflowStore} tenantStore={store.tenantStore} />
          </Route>

          <Route path="/manage/:tenantId">
            <Manage store={store} />
          </Route>

          <Route path="/callback">
            <Callback tenantStore={store.tenantStore} />
          </Route>

          <Route path="/login/:tenantId" component={Login} />

          <Route path="/tenants">
            <TenantList tenantStore={store.tenantStore} />
          </Route>

          <Route path="/" component={Login} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
});

export default App;
