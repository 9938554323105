import {
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

import VpnKeyIcon from '@mui/icons-material/VpnKey';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';

import 'ace-builds';
import 'ace-builds/webpack-resolver';
import { observer } from 'mobx-react-lite';
import AceEditor from 'react-ace';

import HoverableTableRow from './HoverableTableRow';
import Navbar from './Navbar';
import StyledTableCell from './StyledTableCell';
import '../styles/manage.css';

import actionJson from '../actions.json';

import { shouldStringify } from '../utils/helpers';

const Help = observer((props) => {
  const { tenantStore } = props;
  const [currAction, setCurrAction] = useState([Object.keys(actionJson)[0],
    Object.values(actionJson)[0]]);

  return (
    <Box className="manage">
      <Navbar page="help" tenantStore={tenantStore} />
      <Box
        className="panel-container"
      >
        <Box
          className="panel-help"
          sx={{
            padding: 0,
          }}
        >
          <List>
            <ListSubheader
              sticky="true"
              sx={{
                backgroundColor: 'white',
              }}
            >
              <Box
                className="panel-title"
              >
                Action Types
              </Box>
            </ListSubheader>
            {Object.keys(actionJson).map((action) => {
              const mySX = {
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'action.hover',
                  cursor: 'pointer',
                },
              };
              if (action === currAction[0]) {
                mySX.backgroundColor = '#EEF !important';
              }
              return (
                <ListItem
                  divider
                  key={action}
                  onClick={() => setCurrAction([action, actionJson[action]])}
                  onKeyPress={() => setCurrAction([action, actionJson[action]])}
                  sx={mySX}
                  tabIndex={0}
                >
                  <ListItemText
                    primary={action}
                    sx={{
                      padding: '0px 15px 0px 0px',
                    }}
                  />

                  {(actionJson[action].unstable && (
                    <Tooltip title="Unstable" placement="top" arrow sx={{ color: 'rgba(0, 0, 0, 0.54)', paddingLeft: '5px' }}>
                      {(action === currAction[0])
                        ? (<WarningOutlinedIcon />) : (<WarningAmberIcon />)}
                    </Tooltip>
                  ))}

                  {(actionJson[action].userPassReqd && (
                    <Tooltip title="Author password required" placement="top" arrow sx={{ color: 'rgba(0, 0, 0, 0.54)', paddingLeft: '5px' }}>
                      {(action === currAction[0])
                        ? (<VpnKeyIcon />) : (<VpnKeyOutlinedIcon />)}
                    </Tooltip>
                  ))}

                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box className="panel" style={{ flex: 2 }}>
          <Box className="panel-title">
            Details
          </Box>
          <Box sx={{ margin: '10px' }}>
            <Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                Action Type:
              </Typography>
              <Box style={{ paddingLeft: '10px' }}>
                {currAction[0]}
                {(currAction[1].unstable && (
                  <Tooltip title="This action is considered unstable and may not work" placement="top" leaveDelay={700} arrow>
                    <Chip
                      size="medium"
                      color="secondary"
                      icon={<WarningOutlinedIcon />}
                      label="Action is unstable"
                      style={{ float: 'right', marginRight: '40px' }}
                    />
                  </Tooltip>
                ))}
                {(currAction[1].userPassReqd && (
                  <Tooltip title="This action requires the author is a workflow user with a password specified in Automa2n" placement="top" leaveDelay={700} arrow>
                    <Chip
                      size="medium"
                      color="primary"
                      icon={<VpnKeyIcon />}
                      label="Author password required"
                      style={{ float: 'right', marginRight: '40px' }}
                    />
                  </Tooltip>
                ))}
              </Box>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Action Description:
              </Typography>
              <Box sx={{ paddingLeft: '10px' }}>
                {currAction[1].typeDescription}
              </Box>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Attributes:
              </Typography>
            </Box>

            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      Name
                    </StyledTableCell>
                    <StyledTableCell>
                      Required?
                    </StyledTableCell>
                    <StyledTableCell>
                      Default
                    </StyledTableCell>
                    <StyledTableCell>
                      Data Type
                    </StyledTableCell>
                    <StyledTableCell>
                      Description
                    </StyledTableCell>
                    <StyledTableCell>
                      Sample Value
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(currAction[1]).map((property) => {
                    const propertiesNotInAttributesTable = [
                      'typeDescription',
                      'examples',
                      'userPassReqd',
                      'unstable',
                    ];
                    if (propertiesNotInAttributesTable.indexOf(property) < 0) {
                      return (
                        <HoverableTableRow key={new Date().getTime() + property}>
                          <TableCell className="table-cell" align="center">
                            {property}
                          </TableCell>
                          <TableCell className="table-cell" align="center">
                            {currAction[1][property].required ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell className="table-cell" align="center">
                            { (shouldStringify(currAction[1][property].default)
                              ? (JSON.stringify(currAction[1][property].default)) : currAction[1][property].default) || '-'}
                          </TableCell>
                          <TableCell className="table-cell" align="center">
                            {currAction[1][property].type}
                          </TableCell>
                          <TableCell className="table-cell">
                            {currAction[1][property].desc}
                          </TableCell>
                          <TableCell className="table-cell">
                            {
                              shouldStringify(currAction[1][property].sampleCodeValue)
                                ? (
                                  <AceEditor
                                    mode="json"
                                    theme="github"
                                    maxLines={Infinity}
                                    readOnly
                                    value={
                                      JSON.stringify(
                                        currAction[1][property].sampleCodeValue,
                                        null,
                                        2,
                                      )
                                    }
                                  />
                                ) : (
                                  JSON.stringify(currAction[1][property].sampleCodeValue)
                                )
                            }
                          </TableCell>
                        </HoverableTableRow>
                      );
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box style={{ fontWeight: 'bold', padding: '20px 0px 10px 0px' }}>
              Examples:
            </Box>
            <Grid container spacing={2}>
              <Grid item md={12} lg={6}>
                Required Attributes Only:
                <AceEditor
                  mode="json"
                  theme="github"
                  maxLines={Infinity}
                  readOnly
                  value={JSON.stringify(currAction[1].examples.requiredSampleCodeValue, null, 2)}
                />
              </Grid>
              <Grid item md={12} lg={6}>
                All Attributes:
                <AceEditor
                  mode="json"
                  theme="github"
                  maxLines={Infinity}
                  readOnly
                  value={JSON.stringify(currAction[1].examples.sampleCodeValue, null, 2)}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Help;
