import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Switch,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import 'ace-builds';
import 'ace-builds/webpack-resolver';
import AceEditor from 'react-ace';

import { FileDrop } from 'react-file-drop';
import { beautifyDate, sleep } from '../../utils/helpers';
import {
  createNewUser,
  editUser,
  editWorkflow,
  getUserData,
  getWorkflows,
  newWorkflow,
} from '../../utils/requests';

// length of time (in ms) to sleep between API calls to make it easier to see what is happening
const sleepTime = 250;

const ImportModal = observer((props) => {
  const {
    open, onClose, userStore, workflowStore,
  } = props;

  const bottomRef = useRef(null);
  const [tenantImport, setTenantImport] = useState('');
  const [shouldOverwriteExisting, setShouldOverwriteExisting] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const reader = new FileReader();
  const fileInputRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [logs]);

  const refreshData = async () => {
    const workflowRes = await getWorkflows();
    if (workflowRes.status === 200) workflowStore.setWorkflows(workflowRes.data);
    const userRes = await getUserData();
    if (userRes.status === 200) userStore.setUsers(userRes.data);
  };

  const addToLogs = (log) => {
    setLogs((prevLogs) => [...prevLogs, log]);
  };

  const onSubmit = async () => {
    setConfirmed(true);
    setLogs([]);

    let firstApiRequest = true;

    try {
      // start importing everything in
      const tenantParsed = JSON.parse(tenantImport);

      // Insert users
      let log = {};
      if (!tenantParsed.users) {
        log = {
          text: 'No users array specified, not adding users.',
          date: beautifyDate(Date.now()),
          error: false,
        };
        addToLogs(log);
      } else if (tenantParsed.users.length === 0) {
        log = {
          text: 'Users array empty, not adding users.',
          date: beautifyDate(Date.now()),
          error: false,
        };
        addToLogs(log);
      } else {
        /* eslint-disable no-await-in-loop */
        for (let i = 0; i < tenantParsed.users.length; i += 1) {
          let fnUser;
          log = {};
          const currentUser = tenantParsed.users[i];
          const isExistingUser = userStore.users.some((e) => e.username === currentUser.username);

          // Skips user if we don't want overwrite behaviour
          if (isExistingUser && !shouldOverwriteExisting) {
            log = {
              text: `${currentUser.username} already exists. Skipping...`,
              date: beautifyDate(Date.now()),
              error: true,
            };
            addToLogs(log);
            // eslint-disable-next-line no-continue
            continue;
          } else if (isExistingUser && shouldOverwriteExisting) {
            // If overwrite and existing user then we need to call edit api
            log = {
              text: `${currentUser.username} already exists. Overwriting...`,
              date: beautifyDate(Date.now()),
              error: false,
            };
            addToLogs(log);

            currentUser.oldUsername = currentUser.username; // Required field for the editUser api
            // res = await editUser(currentUser);
            fnUser = editUser;
          } else {
            // Else user exists, we edit instead of create new user
            // res = await createNewUser(currentUser);
            fnUser = createNewUser;
          }

          // we want to add a sleep in-between API requests to reduce workload
          if (!firstApiRequest) {
            await sleep(sleepTime);
          } else {
            firstApiRequest = false;
          }

          const res = await fnUser(currentUser);

          if (res.status === 200) {
            log = {
              text: `${currentUser.username} successfully added/updated.`,
              date: beautifyDate(Date.now()),
            };
          } else {
            let extraText;
            if (res.status === 0) {
              extraText = 'API server unreachable.';
            } else {
              extraText = res.data.msg;
            }
            log = {
              text: `Error adding/updating ${currentUser.username}.`,
              date: extraText,
              error: true,
            };
          }
          addToLogs(log);
        }
      }

      // Insert workflows
      if (!tenantParsed.workflows) {
        log = {
          text: 'No workflows array specified, not adding workflows.',
          date: beautifyDate(Date.now()),
          error: false,
        };
        addToLogs(log);
      } else if (tenantParsed.workflows.length === 0) {
        log = {
          text: 'Workflows array empty, not adding workflows.',
          date: beautifyDate(Date.now()),
          error: false,
        };
        addToLogs(log);
      } else {
        for (let i = 0; i < tenantParsed.workflows.length; i += 1) {
          log = {};
          const currentWorkflow = tenantParsed.workflows[i];

          const existingWorkflow = workflowStore.workflows.find(
            (workflow) => workflow.name === currentWorkflow.name,
          );

          for (let x = 0; x < currentWorkflow.versions.length; x += 1) {
            log = {};
            const currentWorkflowVersion = currentWorkflow.versions[x];
            let res;

            // If this workflow is new we can just add it
            // A new workflow will have no preexisting versions
            if (!existingWorkflow) {
              res = await newWorkflow(
                currentWorkflow.name,
                currentWorkflowVersion.version,
                currentWorkflowVersion.isActive,
                JSON.stringify(currentWorkflowVersion.actions),
                currentWorkflowVersion.isDefault,
              );
            } else {
              // If a workflow already exists,
              // we need to check each version number to see if its new or not
              // we also need to factor in whether the user wants to overwrite the existing version
              const existingVersionObj = existingWorkflow.versions.find(
                (versionObj) => versionObj.version === currentWorkflowVersion.version,
              );

              // Skips workflow version if we don't want overwriting behaviour
              if (existingVersionObj && !shouldOverwriteExisting) {
                log = {
                  text: `Workflow "${currentWorkflow.name}" with version "${currentWorkflowVersion.version}" already exists. Skipping...`,
                  date: beautifyDate(Date.now()),
                  error: true,
                };
                addToLogs(log);
                // eslint-disable-next-line no-continue
                continue;
              } else if (existingVersionObj && shouldOverwriteExisting) {
                // If version exists and we should overwrite, we edit instead of create

                log = {
                  text: `Workflow "${currentWorkflow.name}" with version "${currentWorkflowVersion.version}" already exists. Overwriting...`,
                  date: beautifyDate(Date.now()),
                  error: false,
                };
                addToLogs(log);
                res = await editWorkflow(
                  existingVersionObj.workflowId,
                  existingVersionObj.versionId,
                  currentWorkflowVersion.isActive,
                  JSON.stringify(currentWorkflowVersion.actions),
                );
              } else {
                // Else its a new workflow version
                res = await newWorkflow(
                  currentWorkflow.name,
                  currentWorkflowVersion.version,
                  currentWorkflowVersion.isActive,
                  JSON.stringify(currentWorkflowVersion.actions),
                  currentWorkflowVersion.isDefault,
                );
              }
            }

            // we want to add a sleep in-between API requests to reduce workload
            if (!firstApiRequest) {
              await sleep(sleepTime);
            } else {
              firstApiRequest = false;
            }

            if (res.status === 200) {
              log = {
                text: `Workflow "${currentWorkflow.name}", version ${currentWorkflowVersion.version} successfully added/updated.`,
                date: beautifyDate(Date.now()),
              };
            } else {
              let extraText;
              if (res.status === 0) {
                extraText = 'API server unreachable.';
              } else {
                extraText = res.data.map((e) => `${e.path} - ${e.msg}`).join(' | ');
              }
              log = {
                text: `Error adding/updating Workflow "${currentWorkflow.name}", version ${currentWorkflowVersion.version}.`,
                date: extraText,
                error: true,
              };
            }
            addToLogs(log);
          }
        }
      }
    } catch (err) {
      const log = {
        text: `Error: ${err.message}`,
        date: beautifyDate(Date.now()),
        error: true,
      };
      addToLogs(log);
    }
    /* eslint-enable no-await-in-loop */
    // Set default version.

    // we only need to call refreshData if changes have been made
    if (!firstApiRequest) {
      await refreshData();
    }

    setLoading(false);
  };

  const handleChange = (e) => {
    setTenantImport(e);
    setConfirmed(false);
    setLoading(true);
    if (e === '') {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
      setTenantImport('');
      setEmpty(true);
      setConfirmed(false);
      setLogs([]);
      setLoading(true);
    }
  };

  reader.onload = () => {
    const res = reader.result;
    setTenantImport(res);
    setConfirmed(false);
    setLoading(true);
    if (res === '') {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  };

  const handleFileInput = (files) => {
    if (files[0]) {
      reader.readAsText(files[0]);
    }
  };

  const onFileInputChange = (event) => {
    const { files } = event.target;
    handleFileInput(files);
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth
    >
      <DialogTitle style={{ borderBottom: '1px solid' }}>
        Import Users and Workflows
      </DialogTitle>
      <DialogContent>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              Paste the contents of the tenant import here:
            </div>
          </Grid>
          <Grid item xs={9}>
            {/* <TextField
              multiline
              disabled={confirmed && loading}
              rows={8}
              value={tenantImport}
              style={{
                width: '100%',
                gridArea: 'main',
              }}
              variant="outlined"
              name="Tenant Import"
              id="TenantImport"
              onChange={handleChange}
            /> */}

            <AceEditor
              mode="json"
              theme="github"
              width="100%"
              readOnly={confirmed && loading}
              focus={!confirmed || !loading}
              wrapEnabled
              value={tenantImport}
              maxLines={12}
              minLines={12}
              placeholder="Workflow import JSON value"
              name="Tenant Import"
              id="TenantImport"
              tabSize={2}
              style={{
                border: '1px solid lightgray',
              }}
              onChange={handleChange}
            />

          </Grid>
          <Grid item xs={3}>
            <div
              style={{
                gridArea: 'drop',
                position: 'relative',
                border: '1px dashed #404040',
                borderRadius: '0.3rem',
                color: '#404040',
                width: '100%',
                height: '100%',
              }}
            >
              <FileDrop
                onDrop={handleFileInput}
                onTargetClick={onTargetClick}
              >
                <CloudUploadOutlinedIcon fontSize="large" />
                <div>Drag and drop</div>
                <div>or</div>
                <div>click to select a file to fill the textbox.</div>
              </FileDrop>
            </div>
            <input
              hidden
              type="file"
              accept=".json"
              ref={fileInputRef}
              onChange={onFileInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={(
                  <Switch
                    checked={shouldOverwriteExisting}
                    onChange={() => setShouldOverwriteExisting(!shouldOverwriteExisting)}
                    name="inactive"
                    color="primary"
                  />
                )}
                label="Overwrite existing versions and users"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <List
              style={{
                gridArea: 'footer',
              }}
            >
              {logs.map((action) => (
                <ListItem value={action} key={action.text}>
                  <ListItemText primary={action.text} secondary={action.date} primaryTypographyProps={action.error ? { color: 'error' } : { color: 'primary' }} />
                </ListItem>
              ))}
              <ListItem>
                {(loading && confirmed) && <CircularProgress />}
              </ListItem>
              <ListItem ref={bottomRef} />
            </List>
          </Grid>
        </Grid>

      </DialogContent>

      <DialogActions style={{
        display: 'flex', justifyContent: 'center', borderTop: '1px solid', marginTop: 20, padding: 16,
      }}
      >
        {
          !confirmed ? (
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={empty}
                autoFocus
                onClick={onSubmit}
                style={{ margin: '0 5px 0 5px', width: 100 }}
              >
                Confirm
              </Button>
              <Button variant="contained" color="secondary" onClick={handleClose} style={{ margin: '0 5px 0 5px', width: 100 }}>
                Cancel
              </Button>
            </div>
          ) : (
            <div>
              <Button variant="contained" color="secondary" disabled={loading} onClick={handleClose} style={{ margin: '0 5px 0 5px', width: 100 }}>
                Close
              </Button>
            </div>
          )
        }
      </DialogActions>
    </Dialog>
  );
});

ImportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userStore: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  workflowStore: PropTypes.object,
};

export default ImportModal;
