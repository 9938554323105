import React, { useEffect, useState } from 'react';

import CodeIcon from '@mui/icons-material/Code';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import 'ace-builds';
import 'ace-builds/webpack-resolver';
import AceEditor from 'react-ace';

import StyledTableCell from './StyledTableCell';

import { beautifyDate, getRunIdForDisplay } from '../utils/helpers';

export default function IndividualWorkflowRunLog({ workflowRunData, secondsTillRetry }) {
  const [showActionPreview, setShowActionPreview] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState(workflowRunData.actionResults);
  const [actionInfoTableData, setActionInfoTableData] = useState({});
  const [actionStatusChipData, setActionStatusChipData] = useState({});
  const [sortHeader, setSortHeader] = useState(() => 'Completed');
  const [actionResultsText, setActionsResultsText] = useState('');
  const [actionsToComplete, setActionsToComplete] = useState(0);
  const [clipboardCopyOpen, setClipboardCopyOpen] = useState(false);

  const status = { Success: 'green', Skipped: 'orange', Failure: 'red' };

  const [statusFilterIndex] = useState({
    failure: 'Failure',
    success: 'Success',
    skipped: 'Skipped',
    all: 'any',
  });

  const [headerToStatusFilterIndex] = useState({
    'Critical Failures': statusFilterIndex.failure,
    Successes: statusFilterIndex.success,
    Skipped: statusFilterIndex.skipped,
    Failures: statusFilterIndex.failure,
    Completed: statusFilterIndex.all,
  });

  const [criticalActions] = useState([
    'usersCreate',
    'usersEnrollment',
  ]);

  useEffect(() => {
    const filter = headerToStatusFilterIndex[sortHeader];
    let newData;
    if (filter === 'any') {
      newData = workflowRunData.actionResults;
    } else {
      newData = workflowRunData.actionResults.filter((data) => data.status === filter);
      if (sortHeader === 'Critical Failures') {
        newData = newData.filter((data) => criticalActions.includes(data.actionType));
      }
    }
    setDataToDisplay(newData);
  }, [
    sortHeader,
    workflowRunData.actionResults,
    headerToStatusFilterIndex,
    criticalActions,
  ]);

  useEffect(() => {
    const workflowInfo = workflowRunData.workflowName
                          && workflowRunData.version
                          && `${workflowRunData.workflowName} (${workflowRunData.version})`;
    const durationInfo = workflowRunData.endDate
                          && workflowRunData.startDate
                          && `${Math.ceil((
                            new Date(workflowRunData.endDate)
                                - new Date(workflowRunData.startDate)
                          ) / 1000)} (s)`;

    setActionInfoTableData({
      'Run Id': workflowRunData.runId,
      Workflow: workflowInfo,
      'Status Message': workflowRunData.overallStatusMessage,
      'Start Date': workflowRunData.startDate && beautifyDate(workflowRunData.startDate),
      Duration: durationInfo,
    });
  }, [
    workflowRunData.runId,
    workflowRunData.workflowName,
    workflowRunData.version,
    workflowRunData.overallStatusMessage,
    workflowRunData.endDate,
    workflowRunData.startDate,
  ]);

  useEffect(() => {
    const numActions = workflowRunData.actionsToComplete || 0;
    setActionsToComplete(numActions);

    setActionStatusChipData({
      Completed: workflowRunData.completedActions,
      'Critical Failures': workflowRunData.criticalFailedActions,
      Successes: workflowRunData.successfulActions,
      Failures: workflowRunData.failedActions,
      Skipped: workflowRunData.skippedActions,
    });
  }, [
    workflowRunData.actionsToComplete,
    workflowRunData.completedActions,
    workflowRunData.criticalFailedActions,
    workflowRunData.failedActions,
    workflowRunData.skippedActions,
    workflowRunData.successfulActions,
  ]);

  useEffect(() => {
    let resultsText = '';
    if (dataToDisplay) {
      resultsText = JSON.stringify(dataToDisplay, null, 2);
    }
    setActionsResultsText(resultsText);
  }, [dataToDisplay]);

  return (
    <>
      <Snackbar
        open={clipboardCopyOpen}
        autoHideDuration={3000}
        onClose={() => setClipboardCopyOpen(false)}
      >
        <Alert severity="info">Workflow run value copied to clipboard</Alert>
      </Snackbar>
      <Grid container sx={{ width: '100%', height: '35px' }} alignItems="center">
        <Grid item>
          <Typography variant="h6" sx={{ paddingRight: '15px' }}>LOG:</Typography>
        </Grid>
        <Grid item>
          <CircularProgress
            variant="determinate"
            value={(5 - (secondsTillRetry ?? 5)) * 25}
            size={20}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={5}
        sx={{ paddingTop: '15px' }}
      >
        <Grid
          item
          xs={4}
        >
          <Box>
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {Object.keys(actionInfoTableData).map((header) => {
                      if (actionInfoTableData[header]) {
                        const isRunId = (header === 'Run Id');
                        const rawTableData = actionInfoTableData[header];
                        const formattedTableData = (isRunId)
                          ? getRunIdForDisplay(rawTableData)
                          : rawTableData;
                        const dataTitle = (isRunId) ? rawTableData : formattedTableData;
                        return (
                          <React.Fragment key={header}>
                            <TableRow>
                              <StyledTableCell>
                                <Typography>
                                  {header}
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center">
                                <Tooltip title={dataTitle}>
                                  <Typography
                                    sx={{ wordBreak: 'break-word' }}
                                    onClick={() => {
                                      navigator.clipboard.writeText(dataTitle);
                                      setClipboardCopyOpen(true);
                                    }}
                                  >
                                    {formattedTableData}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      }
                      return '';
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1} justifyContent="space-between" sx={{ paddingBottom: '5px' }}>
            <Grid item xs={2}>
              <Box
                textAlign="center"
                sx={
                  {
                    paddingLeft: '5px',
                    border: 'solid 1px #DDDDDD',
                    minHeight: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                }
              >
                <Typography
                  component="span"
                  display={(actionsToComplete !== 0)}
                >
                  {`Actions: ${actionsToComplete}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8} sx={{ width: '100%' }}>
              <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ minHeight: '35px' }}>
                {Object.keys(actionStatusChipData).map((header) => {
                  if (actionStatusChipData[header]) {
                    let chipColour = 'info';
                    switch (headerToStatusFilterIndex[header]) {
                      case statusFilterIndex.failure:
                        chipColour = 'error';
                        break;
                      case statusFilterIndex.success:
                        chipColour = 'success';
                        break;
                      case statusFilterIndex.skipped:
                        chipColour = 'warning';
                        break;
                      default:
                        chipColour = 'info';
                    }
                    return (
                      <Chip
                        key={header}
                        sx={{
                          cursor: 'pointer',
                          marginTop: '2px',
                          marginBottom: '2px',
                        }}
                        onClick={() => setSortHeader(header)}
                        variant={(sortHeader === header) ? 'filled' : 'outlined'}
                        color={chipColour}
                        avatar={<Avatar>{actionStatusChipData[header]}</Avatar>}
                        label={header}
                      />
                    );
                  }
                  return '';
                })}
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                onClick={() => setShowActionPreview(!showActionPreview)}
                sx={{
                  marginRight: '10px',
                  float: 'right',
                }}
              >
                {
                  showActionPreview
                    ? (
                      <Tooltip title="Show List">
                        <FormatListBulletedIcon color="primary" fontSize="small" aria-label="Show List" />
                      </Tooltip>
                    )
                    : (
                      <Tooltip title="Show Text">
                        <CodeIcon color="primary" fontSize="small" aria-label="Show Text" />
                      </Tooltip>
                    )
                }
              </Button>
            </Grid>
          </Grid>
          {showActionPreview
            ? (
              <Paper
                variant="outlined"
              >
                <AceEditor
                  mode="json"
                  theme="github"
                  height="69vh"
                  width="100%"
                  readOnly
                  value={actionResultsText}
                />
              </Paper>
            )
            : (
              <Paper
                variant="outlined"
                sx={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  height: '69vh',
                  overflowX: 'hidden',
                }}
              >
                {dataToDisplay.map((obj) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Accordion
                    defaultExpanded={(obj.status !== 'Success')}
                    style={{ border: '1px solid', margin: '10px 0 10px 0' }}
                    key={obj.actionPath}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ color: `${status[obj.status]}` }}
                    >
                      <Typography>
                        <span
                          style={{
                            color: '#7e8077',
                            display: 'inline',
                          }}
                        >
                          {`(${obj.actionPath})`}
                        </span>
                        {' '}
                        {obj.actionType}
                        {' '}
                        -
                        {' '}
                        {obj.status}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box>
                        {obj.actionPath ? (
                          <Typography>
                            Path :
                            (
                            {obj.actionPath ?? ''}
                            )
                          </Typography>
                        ) : ''}
                        {obj.msg ? (
                          <Typography>
                            Message :
                            {' '}
                            {obj.msg}
                          </Typography>
                        ) : ''}
                        <Typography>
                          Execution Start Time:
                          {' '}
                          {beautifyDate(obj.startTime)}
                        </Typography>
                        <Typography>
                          Duration:
                          {' '}
                          {Math.ceil((new Date(obj.endTime) - new Date(obj.startTime)) / 1000)}
                          {' '}
                          (s)
                        </Typography>
                      </Box>

                    </AccordionDetails>

                  </Accordion>
                ))}

              </Paper>
            )}
        </Grid>

      </Grid>
    </>
  );
}

IndividualWorkflowRunLog.propTypes = {
  secondsTillRetry: PropTypes.number,
  workflowRunData: PropTypes.shape(
    {
      polled: PropTypes.bool,
      workflowName: PropTypes.string,
      version: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      runId: PropTypes.string,
      actionsToComplete: PropTypes.number,
      completedActions: PropTypes.number,
      criticalFailedActions: PropTypes.number,
      successfulActions: PropTypes.number,
      failedActions: PropTypes.number,
      skippedActions: PropTypes.number,
      overallStatusMessage: PropTypes.string,
      actionResults: PropTypes.arrayOf(PropTypes.shape({
        msg: PropTypes.string,
        actionType: PropTypes.string,
        status: PropTypes.string,
        startTime: PropTypes.string,
        endTime: PropTypes.string,
      })).isRequired,
    },
  ).isRequired,
};

IndividualWorkflowRunLog.defaultProps = {
  secondsTillRetry: null,
};
