/* eslint-disable no-nested-ternary */
/* eslint-disable no-await-in-loop */
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import WarningAmber from '@mui/icons-material/WarningAmber';
import LoadingSpinner from './LoadingSpinner';

import { createNewUser, editUser } from '../../utils/requests';

const CreateWorkflowUsersModal = observer((props) => {
  const {
    open, onCancel, userStates, refreshUsers, usedUsers, userStore,
  } = props;

  const [allChecked, setAllChecked] = React.useState(true);
  const [creatingUsers, setCreatingUsers] = React.useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);
  const [stateUsedUsers, setStateUsedUsers] = React.useState([]);
  const [numProblems, setNumProblems] = React.useState(0);

  useEffect(() => {
    const newAllChecked = true;
    const newStateUsedUsers = [];
    const usersFilter = (user) => {
      const result = [userStates.invalidUser, userStates.needsPassword].includes(user.state)
                      && user.username !== '$ADMIN$';
      return result;
    };

    Object.keys(usedUsers)
      .forEach((username) => {
        const user = usedUsers[username];
        if (usersFilter(user)) {
          newStateUsedUsers.push({
            username,
            password: '',
            description: '',
            isActive: 1,
            changePassword: false,
            checked: newAllChecked,
            needsPass: user.needsPass,
            state: user.state,
            type: user.type,
            done: false,
            problems: new Set(),
            created: false,
          });
        }
      });
    setNumProblems(newStateUsedUsers.length);
    setStateUsedUsers(newStateUsedUsers);
    setAllChecked(newAllChecked);
    if (newStateUsedUsers.length === 0) {
      onCancel();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedUsers, open, userStates]);

  const handleToggle = (username) => {
    setStateUsedUsers((oldStateUsedUsers) => {
      const newStateUsedUsers = [...oldStateUsedUsers];
      const index = newStateUsedUsers.findIndex((element) => element.username === username);
      if (index !== undefined) {
        if (allChecked) {
          setAllChecked(false);
        }
        newStateUsedUsers[index].checked = !newStateUsedUsers[index].checked;
      }
      return newStateUsedUsers;
    });
  };

  // creates all users held within stateUsedUsers that are checked
  const createUsers = async () => {
    if (!creatingUsers) {
      setCreatingUsers(true);
      setShowLoadingSpinner(true);

      setStateUsedUsers(
        (oldStateUsedUsers) => oldStateUsedUsers.map((user) => ({ ...user, problems: new Set() })),
      );

      const causeToDisplayProblemIndex = {
        'invalid username/password': 'incorrect password',
      };

      let countProblems = 0;
      for (let i = 0; i < stateUsedUsers.length; i += 1) {
        const user = stateUsedUsers[i];
        user.problems = new Set();

        if (user.checked && !user.done) {
          const userAlreadyInTenant = userStore.users.find(
            (tempUser) => tempUser.username === user.username,
          );
          const needsPasswordAndNotGiven = (user.state === userStates.needsPassword && user.password === '');
          if (needsPasswordAndNotGiven) {
            user.problems.add('user needs password');
            countProblems += 1;
          }

          let validInputs = true;
          let res;
          if (userAlreadyInTenant) {
            user.created = false;
            if (!needsPasswordAndNotGiven) {
              user.changePassword = user.needsPass || user.password;
              res = await editUser(user);
            } else {
              validInputs = false;
            }
          } else {
            res = await createNewUser(user);
            if (res.status === 200) {
              user.created = true;
            }
          }

          if (validInputs) {
            const cause = res?.data.msg ?? 'unknown cause';
            if (res.status === 200) {
              if (!needsPasswordAndNotGiven) {
                user.done = true;
              }
            } else {
              countProblems += 1;
              user.problems.add(causeToDisplayProblemIndex[cause] ?? cause);
            }
          }

          setNumProblems(countProblems);

          setStateUsedUsers((oldStateUsedUsers) => {
            const newStateUsedUsers = [...oldStateUsedUsers];
            const index = newStateUsedUsers
              .findIndex((tempUser) => tempUser.username === user.username);
            if (index !== undefined) {
              newStateUsedUsers[index] = user;
            }
            return newStateUsedUsers;
          });
        }
      }
      await refreshUsers();
      setCreatingUsers(false);
      setShowLoadingSpinner(false);
    }
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
    >
      <LoadingSpinner show={showLoadingSpinner} />

      <List
        sx={{
          padding: '20px',
        }}
        subheader="Click 'Create' to create the selected users (with password if provided)"
        dense
      >
        {/* check all */}
        <ListItem divider>
          <ListItemIcon>
            <Checkbox
              onClick={() => {
                const newAllChecked = !allChecked;
                setStateUsedUsers((oldStateUsedUsers) => oldStateUsedUsers
                  .map((user) => ({ ...user, checked: newAllChecked })));
                setAllChecked(newAllChecked);
              }}
              checked={allChecked}
            />
          </ListItemIcon>
          <Grid
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs={5}
              align="center"
            >
              <strong>Username</strong>
            </Grid>
            <Grid
              item
              xs={5}
              align="center"
            >
              <strong>Password</strong>
            </Grid>
            <Grid item xs={2} align="center">
              <strong>Required</strong>

            </Grid>
          </Grid>
        </ListItem>
        {stateUsedUsers
          .map((user, userIndex) => (
            <ListItem
              divider={!(userIndex === stateUsedUsers.length - 1)}
              alignItems="flex-start"
              key={user.username}
            >
              <ListItemIcon sx={{ display: (user.done) ? 'none' : '' }}>
                <Checkbox
                  onClick={() => handleToggle(user.username)}
                  checked={user.checked}
                  inputProps={{ 'aria-label': user.username }}
                />
              </ListItemIcon>
              <Grid
                container
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid
                  item
                  xs={5}
                  align="center"
                  sx={{ display: (user.done) ? 'none' : '' }}
                >
                  <Typography>{user.username}</Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  align="center"
                  sx={{ display: (user.done) ? 'none' : '' }}
                >
                  {(user.done)
                    ? (
                      <Typography sx={{ color: 'green' }}>Created</Typography>
                    )
                    : (
                      <TextField
                        type="password"
                        onChange={(event) => {
                          setStateUsedUsers((oldStateUsedUsers) => {
                            const newStateUsedUsers = [...oldStateUsedUsers];
                            const index = newStateUsedUsers
                              .findIndex((tempUser) => tempUser.username === user.username);
                            if (index !== undefined) {
                              newStateUsedUsers[index].password = event.target.value;
                            }
                            return newStateUsedUsers;
                          });
                        }}
                        value={stateUsedUsers.find((tempUser) => tempUser.username === user.username).password ?? ''}
                        variant="outlined"
                      />
                    )}
                </Grid>
                <Grid item xs={2} align="center">
                  {((user.state === userStates.needsPassword) && (!user.done))
                    ? (
                      <Tooltip title="Password is required for this user" placement="top" arrow>
                        <WarningAmber sx={{ color: 'red' }} />
                      </Tooltip>
                    ) : ''}

                </Grid>
                {(() => {
                  const userExists = userStore.users.find((u) => u.username === user.username);
                  const arrProblems = Array.from(user.problems);
                  let severity;
                  let alertMessage;
                  let showAlert = false;

                  if (user.done) {
                    showAlert = true;
                    severity = 'success';
                    alertMessage = `${
                      (user.created)
                        ? 'Successfully created user '
                        : 'Successfully updated user '
                    } [${user.username}]`;
                  } else if (arrProblems.length && userExists) {
                    showAlert = true;
                    severity = 'warning';
                    alertMessage = `${
                      (user.created)
                        ? `Successfully created user [${user.username}]`
                        : `User [${user.username}] exists in system`
                    }, but problems remain: ${arrProblems.join(', ')}`;
                  } else if (arrProblems.length && !userExists) {
                    showAlert = true;
                    severity = 'error';
                    alertMessage = `Error(s) creating user [${user.username}]: ${arrProblems.join(', ')}`;
                  }

                  return (showAlert
                    && (
                      <Grid item xs={12}>
                        <Alert severity={severity}>
                          {alertMessage}
                        </Alert>
                      </Grid>
                    )
                  );
                })()}
              </Grid>
            </ListItem>
          ))}

      </List>
      <DialogActions style={{
        justifyContent: 'center', borderTop: '1px solid black', padding: '16px', marginTop: '20px',
      }}
      >
        <Button
          disabled={(numProblems === 0)}
          onClick={
            async () => {
              await createUsers();
            }
          }
          variant="contained"
          color="primary"
        >
          Create
        </Button>
        <Button onClick={onCancel} variant="contained" color="secondary">Close</Button>
      </DialogActions>
    </Dialog>
  );
});

export default CreateWorkflowUsersModal;
