/* eslint-disable react-hooks/exhaustive-deps */
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LoadingSpinner from './modals/LoadingSpinner';

import { beautifyDate } from '../utils/helpers';

const TenantList = observer((props) => {
  const { tenantStore } = props;
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [sortedColumn, setSortedColumn] = useState('dateLastAccessed');
  const [sortedTenants, setSortedTenants] = useState([...tenantStore.tenants]);
  const defaultSortDirection = false;
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);
  const [searchValue, setSearchValue] = useState('');

  const retrieveTenants = async () => {
    setShowLoadingSpinner(true);
    await tenantStore.getValues();
    setShowLoadingSpinner(false);
  };

  useEffect(() => {
    retrieveTenants();
  }, []);

  useEffect(() => {
    // * all numbers are prepended with some 0's such they become the same length
    // *  and create natural sorting order
    const natTextCleaner = (string) => string.toLowerCase().replace(/\s+/g, ' ').replace(/\d+/g, (p) => '0'.repeat(20 - p.length) + p);

    const natSortFunction = (a, b) => {
      const comp = natTextCleaner(a[sortedColumn]).localeCompare(natTextCleaner(b[sortedColumn]));
      /*
        (2 * sortDirection - 1) converts a boolean (true/false) into a sign (1/-1).
        * if sortDirection is true: 2 * 1 - 1 = 1
        * if sortDirection is false: 2 * 0 - 1 = -1
      */
      return (2 * sortDirection - 1) * comp;
    };

    const tempSortedTenants = tenantStore.tenants.slice().sort(natSortFunction);

    setSortedTenants(tempSortedTenants.slice().filter(
      (x) => x.name.toLowerCase().includes(searchValue.toLowerCase()),
    ));
  }, [sortDirection, sortedColumn, searchValue, tenantStore.tenants]);

  useEffect(() => {
    setSortDirection(defaultSortDirection);
  }, [sortedColumn]);

  const formatDate = (unformattedDate) => {
    if (unformattedDate === '0') {
      return 'N/A';
    }
    return beautifyDate(unformattedDate);
  };

  const headCells = [
    {
      id: 'name',
      label: 'Name',
      simpleRender: true,
    },
    {
      id: 'brightspaceDomain',
      label: 'Brightspace Domain',
      simpleRender: true,
    },
    {
      id: 'tenantId',
      label: 'Identifier',
      simpleRender: true,
    },
    {
      id: 'awsRegion',
      label: 'AWS Region',
      simpleRender: true,
    },
    {
      id: 'dateLastAccessed',
      label: 'Date Last Accessed',
      simpleRender: false,
    },
  ];

  const setLastAccessedDateIfValid = (tenant) => {
    if (tenant.isValid) {
      let tenantIdDateIndex = window.localStorage.getItem('tenantIdDateIndex') || '{}';
      tenantIdDateIndex = JSON.parse(tenantIdDateIndex);
      tenantIdDateIndex[tenant.tenantId] = (new Date()).toISOString();
      window.localStorage.setItem('tenantIdDateIndex', JSON.stringify(tenantIdDateIndex));
    }
  };

  const openTenantIfValid = (tenant) => {
    if (tenant.isValid) {
      setLastAccessedDateIfValid(tenant);
      document.location.href = `/login/${tenant.tenantId}/?auto=1`;
    }
  };

  return (
    <Box>
      <LoadingSpinner show={showLoadingSpinner} />
      <Box sx={
        {
          maxHeight: '94vh',
          width: '75vw',
          overflowY: 'scroll',
          border: '1px solid #AAA',
          position: 'fixed',
          top: '3vh',
          left: '50%',
          transform: 'translate(-50%, 0)',
        }
      }
      >
        <Box sx={
          {
            margin: '10px 10px 10px 10px',
          }
        }
        >
          <TextField id="searchBar" label="Search" variant="standard" onChange={(e) => setSearchValue(e.target.value)} />
        </Box>
        <Box>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headCells.map((header) => (
                  <TableCell className="table-cell" align="center" key={header.id}>
                    <Typography onClick={() => ((sortedColumn === header.id)
                      ? setSortDirection(!sortDirection) : setSortedColumn(header.id))}
                    >
                      <TableSortLabel
                        active={sortedColumn === header.id}
                        // eslint-disable-next-line no-nested-ternary
                        direction={(sortedColumn === header.id) ? (sortDirection ? 'asc' : 'desc') : 'desc'}
                      >
                        {header.label}
                      </TableSortLabel>
                    </Typography>
                  </TableCell>
                ))}
                <TableCell className="table-cell">
                  <LaunchOutlinedIcon />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTenants.map((tenant) => (
                <TableRow
                  key={tenant.tenantId}
                  hover={tenant.isValid}
                  sx={(tenant.isValid) ? { cursor: 'pointer', '&:hover': { backgroundColor: '#EEF !important' } } : { backgroundColor: '#EEE' }}
                >
                  {headCells.filter((x) => (x.simpleRender)).map((header) => (
                    <TableCell className="table-cell" align="center" key={header.id} onClick={() => openTenantIfValid(tenant)}>
                      <Typography>
                        {tenant[header.id]}
                        {
                          (header.id === 'name' && tenant?.useDdb)
                            ? (
                              <StorageOutlinedIcon
                                fontSize="small"
                                sx={{ marginLeft: '5px', verticalAlign: 'middle' }}
                                aria-label={`${tenant[header.id]} Uses DynamoDB`}
                                titleAccess={`${tenant[header.id]} Uses DynamoDB`}
                              />
                            )
                            : ''
                        }
                      </Typography>
                    </TableCell>
                  ))}
                  <TableCell className="table-cell" align="center" key="dateLastAccessed" onClick={() => openTenantIfValid(tenant)}>
                    <Typography>
                      {formatDate(tenant.dateLastAccessed)}
                    </Typography>
                  </TableCell>
                  <TableCell className="table-cell">
                    {
                      (tenant.isValid)
                        ? (
                          <Link
                            to={`/login/${tenant.tenantId}/?auto=1`}
                            target="_blank"
                            onClick={() => setLastAccessedDateIfValid(tenant)}
                          >
                            <LaunchOutlinedIcon />
                          </Link>
                        )
                        : <Typography>-</Typography>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
});

export default TenantList;
