import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Tooltip, Typography } from '@mui/material';

import { getWebToken } from '../utils/requests';
import { setTenantJwt, setTenantJwtExpiry } from '../utils/helpers';
import Alert from './modals/Alert';
import LoadingSpinner from './modals/LoadingSpinner';

export default function Callback(props) {
  const history = useHistory();
  const location = useLocation();
  const { tenantStore } = props;
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const tenantId = params.get('state');

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
  const [alertState, setAlertState] = useState({ open: false, text: 'placeholder', type: 'success' });
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    if (typeof code === 'string') {
      getWebToken('auth', code).then(async (res) => {
        if (res.status === 200) {
          const { jwtToken, expiry } = res.data;
          setTenantJwt(tenantId, jwtToken);
          setTenantJwtExpiry(tenantId, expiry);
          tenantStore.getValues();
          if (tenantId === process.env.REACT_APP_ROOT_TENANT_ID) {
            history.push('/tenants');
          } else {
            tenantStore.setCurrTenant(tenantId);
            history.push(`/manage/${tenantId}`);
          }
        } else {
          let errorMsg = 'An unexpected error has occurred.';

          if (res.status === 403) {
            const errorDetails = (typeof res.data === 'string') ? res.data : 'Unknown error';
            errorMsg = `Authentication failure: ${errorDetails}`;
          }
          setShowLoadingSpinner(false);
          setLoginError(true);
          setAlertState({
            open: true,
            type: 'error',
            text: errorMsg,
          });
        }
      });
    }
  }, [code, history, tenantId, tenantStore]);

  return (
    <>
      <Alert
        open={alertState.open}
        onClose={() => setAlertState({ ...alertState, open: false })}
        text={alertState.text}
        type={alertState.type}
      />
      <LoadingSpinner show={showLoadingSpinner} />
      {
        (!loginError)
          ? (null)
          : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
              variant="h2"
              sx={{ color: 'red' }}
            >
              <Tooltip title={alertState.error}>
                <Typography>
                  {alertState.text}
                </Typography>
              </Tooltip>
            </Box>
          )
      }
    </>
  );
}

Callback.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tenantStore: PropTypes.object.isRequired,
};
