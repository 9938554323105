import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import '../styles/manage.css';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { getWorkflowRunLogs, getWorkflows, runWorkflow } from '../utils/requests';
import Navbar from './Navbar';
import Alert from './modals/Alert';
import LoadingSpinner from './modals/LoadingSpinner';

import { isPasswordField, sleep } from '../utils/helpers';
import IndividualWorkflowRunLog from './IndividualWorkflowRunLog';

const Run = observer((props) => {
  const { workflowStore, tenantStore } = props;
  const [disabled, setDisabled] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [alertData, setAlertData] = useState({ open: false, text: 'success', type: 'success' });
  const [usersInfo, setUsersInfo] = useState([]);
  const [workflowRunData, setWorkflowRunData] = useState({
    actionResults: [],
    name: '',
    version: '',
    polled: false,
    startDate: null,
    endDate: null,
    overallStatusMessage: null,
    secondsTillRetry: null,
  });
  const [secondsTillRetry, setSecondsTillRetry] = useState(null);
  const isMounted = useRef(null);
  const [loginUsername, setLoginUsername] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const domain = tenantStore?.currTenant?.brightspaceDomain;
  useEffect(() => {
    isMounted.current = true;

    async function getData() {
      const res = await getWorkflows();
      if (res.status === 200) {
        workflowStore.setWorkflows(res.data);
      }
    }
    if (!workflowStore.workflows.length) {
      getData();
    }

    return () => {
      isMounted.current = false;
    };
  }, [workflowStore]);

  const processUserInfo = (userParamArray, inputValues) => {
    const userValues = [];
    userParamArray?.forEach((userParam) => {
      const val = {
        username: inputValues[userParam.username],
        password: inputValues[userParam.password],
      };
      userValues.push(val);
    });
    setUsersInfo(userValues);
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'workflow-select':
        workflowStore.setCurrentWorkflow(e.target.value);
        break;
      case 'version-select':
        workflowStore.setCurrentWorkflowVersion(e.target.value);
        break;
      default:
        workflowStore.currentWorkflowVersion?.setInputValues(e.target.name, e.target.value);
    }
  };

  const fetchLogs = async (runId, delay = null) => {
    if (delay) {
      let time = delay;
      while (time > 0) {
        setSecondsTillRetry(Math.ceil(time / 1000));
        // eslint-disable-next-line no-await-in-loop
        await sleep(1000);
        time -= 1000;
      }
    }
    setSecondsTillRetry(null);
    const res = await getWorkflowRunLogs(runId);
    if (res.status === 200 && isMounted.current) {
      const {
        actionResults, workflowName, version, startDate, endDate, overallStatusMessage,
        actionsToComplete, failedActions, skippedActions, successfulActions,
        criticalFailedActions,
      } = res.data[0];

      const numberOfActions = actionResults.length;

      setWorkflowRunData({
        runId,
        actionResults: actionResults || [],
        workflowName,
        actionsToComplete,
        completedActions: numberOfActions,
        criticalFailedActions,
        failedActions,
        skippedActions,
        successfulActions,
        version,
        polled: true,
        startDate,
        endDate,
        overallStatusMessage,
      });
      if (overallStatusMessage === 'In Progress'
        || (process.env.REACT_APP_ENV !== 'production' && overallStatusMessage === 'Completed Non-Deferred Actions')) {
        fetchLogs(runId, 5000);
      } else {
        setDisabled(false);
      }
    }
  };

  const handleRun = async () => {
    const resetRunData = {
      ...workflowRunData,
      actionResults: [],
      name: '',
      version: '',
      polled: false,
      startDate: null,
      endDate: null,
      overallStatusMessage: null,
      actionsToComplete: 0,
      completedActions: 0,
      criticalFailedActions: 0,
      failedActions: 0,
      skippedActions: 0,
      successfulActions: 0,
    };

    setWorkflowRunData(resetRunData);
    setDisabled(true);
    setShowLoading(true);
    const {
      version, inputValues,
    } = workflowStore.currentWorkflowVersion;
    const { name } = workflowStore.currentWorkflow;

    const res = await runWorkflow(name, version, inputValues);
    setShowLoading(false);

    if (res.status === 200) {
      setAlertData({
        open: true,
        type: 'success',
        text: 'Workflow Execution Succeeded',
      });
      processUserInfo(res.data.userInfo, inputValues);
    } else {
      setAlertData({
        open: true,
        type: 'error',
        text: `Workflow Execution Failed: ${res.data.msg}`,
      });
      setDisabled(false);
    }
    const { runId } = res.data;
    if (runId) {
      fetchLogs(runId);
    }
  };

  const formRef = useRef(null);

  const handleUserLogin = async (user) => {
    setLoginUsername(user.username);
    setLoginPassword(user.password);
    // adding a short sleep before submitting the form
    // this allows the form inputs to be set before submitting
    // without sleep form was not fully populated
    await sleep(250);
    formRef.current.dispatchEvent(
      new Event('submit'),
    );
  };

  const getInputValues = (key) => {
    const inputValues = workflowStore.currentWorkflowVersion?.inputValues;
    if (inputValues) {
      const value = inputValues[key];
      if (value) {
        return value;
      }
    }

    return '';
  };

  return (
    <Box className="page">
      <Navbar page="run" tenantStore={tenantStore} />
      <LoadingSpinner show={showLoading} />
      <Alert
        type={alertData.type}
        open={alertData.open}
        text={alertData.text}
        onClose={() => setAlertData({ open: false })}
      />
      <Box className="width-limiter">
        <Box className="panel-container">
          <Paper className="panel">
            <Box style={{ height: 'inherit', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h5">
                Select Workflow
              </Typography>

              <Box>
                <FormControl variant="outlined" style={{ width: '100%', margin: '10px 10px 5px 0' }}>
                  <InputLabel>Workflow</InputLabel>
                  <Select
                    value={workflowStore.currentWorkflow || ''}
                    onChange={handleChange}
                    label="Workflow"
                    name="workflow-select"
                  >
                    <MenuItem value="">
                      <Box sx={{ fontStyle: 'italic' }}>None</Box>
                    </MenuItem>
                    {workflowStore.workflows.map((workflow) => (
                      <MenuItem value={workflow} key={workflow.name}>
                        {workflow.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" style={{ width: '100%', margin: '5px 10px 5px 0' }}>
                  <InputLabel>Version</InputLabel>
                  <Select
                    value={workflowStore.currentWorkflowVersion || ''}
                    onChange={handleChange}
                    label="Version"
                    name="version-select"
                  >
                    <MenuItem value="">
                      <Box sx={{ fontStyle: 'italic' }}>None</Box>
                    </MenuItem>
                    {workflowStore.currentWorkflow?.versions?.map((version) => {
                      if (!version.isActive) return null;
                      return (
                        <MenuItem value={version} key={version.version}>
                          {version.version}
                          {' '}
                          {version.isDefault === 1 ? '(Default)' : ''}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Typography variant="h5">
                Specify Inputs:
              </Typography>

              <Paper
                variant="outlined"
                sx={{
                  flex: 1, overflow: 'auto', padding: '10px', margin: '10px',
                }}
              >
                <Grid
                  container
                  spacing={1}
                >
                  {workflowStore.currentWorkflowVersion?.params?.inputs?.map((input) => (
                    <Grid item xs={13} key={input}>
                      <Typography>
                        {input}
                      </Typography>
                      <TextField variant="outlined" value={getInputValues(input)} name={input} onChange={handleChange} style={{ width: '100%' }} type={isPasswordField(input) ? 'password' : 'text'} />
                    </Grid>
                  ))}

                </Grid>
              </Paper>

              <Box style={{ margin: 10 }}>
                <Button color="primary" variant="contained" disabled={disabled} onClick={handleRun}>
                  Execute Workflow
                </Button>
              </Box>
            </Box>

          </Paper>
          <Paper className="panel" style={{ flex: 2 }}>
            <IndividualWorkflowRunLog
              secondsTillRetry={secondsTillRetry}
              workflowRunData={workflowRunData}
            />
            {usersInfo.length !== 0 && (
              <Box>
                Login as:
                {usersInfo.map((user) => (
                  <Button key={user.username} onClick={() => { handleUserLogin(user); }}>
                    {user.username}
                  </Button>
                ))}
              </Box>
            )}
            <form id="login-form" ref={formRef} action={`${domain}/d2l/lp/auth/login/login.d2l`} onSubmit={() => false} method="post" target="_blank" style={{ visibility: 'hidden' }}>
              <input type="hidden" name="loginPath" id="loginPath" value="/d2l/login" />
              <input type="text" value={loginUsername} onChange={() => true} className="d2l-edit d2l-edit-required" id="userName" name="userName" autoComplete="off" autoCorrect="off" autoCapitalize="off" aria-required="true" />
              <input type="password" value={loginPassword} onChange={() => true} className="d2l-edit d2l-edit-required" id="password" name="password" autoComplete="off" aria-required="true" />
            </form>
          </Paper>
        </Box>
      </Box>
    </Box>

  );
});

export default Run;
