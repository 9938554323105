import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';

import HoverableTableRow from '../HoverableTableRow';
import StyledTableCell from '../StyledTableCell';

function WorkflowErrorModal({ open, data, onClose }) {
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog open={open} maxWidth="md" onClose={handleClose}>
      <DialogTitle style={{
        borderBottom: '1px solid',
      }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Validation Errors
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>

      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                Path
              </StyledTableCell>
              <StyledTableCell>
                Message
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((obj) => (
              <HoverableTableRow key={new Date().getTime() + obj.path + obj.msg}>
                <TableCell>
                  {obj.path}
                </TableCell>
                <TableCell>
                  {obj.msg}
                </TableCell>
              </HoverableTableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}

WorkflowErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WorkflowErrorModal;
