import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import ImportModal from './modals/ImportModal';
import { getTenantId } from '../utils/helpers';

const ImportExport = observer((props) => {
  const { userStore, workflowStore } = props;

  const stateGetTenantId = useCallback(() => getTenantId(), []);
  const [stateTenantId, setStateTenantId] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => setStateTenantId(stateGetTenantId()), [stateGetTenantId]);

  const downloadExport = async () => {
    const element = document.createElement('a');
    const data = {
      users: userStore.users,
      workflows: workflowStore.workflows,
    };

    // Don't give all user info
    data.users = data.users.map((user) => (
      {
        username: user.username,
        description: user.description,
        isActive: user.isActive,
      }));

    // Don't give all workflow info
    data.workflows = data.workflows.map((workflow) => (
      {
        name: workflow.name,
        versions: workflow.versions.map((workflowVersion) => (
          {
            version: workflowVersion.version,
            isActive: workflowVersion.isActive,
            isDefault: workflowVersion.isDefault,
            actions: workflowVersion.actions,
          }
        )),
      }));

    const file = new Blob(
      [JSON.stringify(data, null, 2)],
      { type: 'text/plain;charset=utf-8' },
    );
    element.href = URL.createObjectURL(file);
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    element.download = `${yyyy}-${mm}-${dd}-Automa2n-${stateTenantId.replace(' ', '-')}.json`;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        style={{ padding: '5px', margin: '5px', height: '40px' }}
        onClick={() => setShowModal(true)}
      >
        Import
      </Button>
      <Button variant="contained" color="primary" style={{ padding: '5px', margin: '5px', height: '40px' }} onClick={downloadExport}>
        Export
      </Button>
      <ImportModal
        open={showModal}
        onClose={() => setShowModal(false)}
        workflowStore={workflowStore}
        userStore={userStore}
      />

    </>
  );
});

export default ImportExport;
