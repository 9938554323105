/* eslint-disable no-bitwise */
import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import {
  Box,
  Button,
  Container,
  IconButton,
  Popper,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import { getTenantId } from '../utils/helpers';

const TenantInfoBar = observer((props) => {
  const { tenantStore } = props;

  const stateGetTenantId = useCallback(() => getTenantId(), []);
  const [stateTenantId, setStateTenantId] = useState('');
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    async function updateData() {
      await tenantStore.getValues();
      tenantStore.setCurrTenant(stateTenantId);
    }
    setStateTenantId(stateGetTenantId());
    if (!tenantStore.currTenant) {
      updateData();
    }
  }, [tenantStore, stateGetTenantId, stateTenantId]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{
      backgroundColor: '#DDD', height: '35px', display: 'flex',
    }}
    >
      {tenantStore.currTenant && (
      <Container sx={{ zIndex: 1000 }}>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Box>
            <Button
              size="medium"
              sx={{ color: '#000' }}
              startIcon={<InfoIcon />}
              onClick={handleClick}
            >
              {`Site: ${tenantStore.currTenant.name} (${tenantStore.currTenant.brightspaceDomain})`}
              {
                (tenantStore.currTenant.useDdb)
                  ? (
                    <StorageOutlinedIcon
                      fontSize="small"
                      sx={{ marginLeft: '5px', verticalAlign: 'middle' }}
                      aria-label={`${tenantStore.currTenant.name} Uses DynamoDB`}
                      titleAccess={`${tenantStore.currTenant.name} Uses DynamoDB`}
                    />
                  )
                  : ''
              }
            </Button>
            <Popper
              anchorEl={anchorEl}
              sx={{
                zIndex: '1300 !important',
              }}
              onClose={handleTooltipClose}
              open={open || Boolean(anchorEl)}
              placement="bottom-start"
            >
              <Paper elevation={4} sx={{ minWidth: '400px' }}>
                <IconButton
                  onClick={handleClick}
                  sx={{
                    position: 'fixed',
                    top: 5,
                    right: 5,
                    padding: '10px',
                    zIndex: '2500',
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <List>
                  <ListItem style={{ paddingBottom: '15px' }}>
                    <ListItemText>
                      <Typography variant="h6">Name:</Typography>
                      <Typography>{tenantStore.currTenant.name}</Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem style={{ paddingBottom: '15px' }}>
                    <ListItemText>
                      <Typography variant="h6">Domain:</Typography>
                      <Typography>
                        {tenantStore.currTenant.brightspaceDomain}
                        <IconButton onClick={() => window.open(tenantStore.currTenant.brightspaceDomain, '_blank')}>
                          <OpenInNewIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem style={{ paddingBottom: '15px' }}>
                    <ListItemText>
                      <Typography variant="h6">Tenant ID:</Typography>
                      <Typography>{stateTenantId}</Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem style={{ paddingBottom: '15px' }}>
                    <ListItemText>
                      <Typography variant="h6">AWS Region:</Typography>
                      <Typography>{tenantStore.currTenant.awsRegion}</Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <Typography variant="h6">Admin Username:</Typography>
                      <Typography>{tenantStore.currTenant.adminUsername}</Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Paper>
            </Popper>
          </Box>
        </ClickAwayListener>
      </Container>
      )}
    </Box>
  );
});

export default TenantInfoBar;
