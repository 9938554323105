/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';

import { observer } from 'mobx-react-lite';
import { dbWakeUp } from '../utils/requests';

import '../App.css';
import Alert from './modals/Alert';

function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const Login = observer((props) => {
  const { location } = props;
  const { tenantId } = props.match.params;
  let targetTenantId = tenantId;
  if (!tenantId || tenantId === 'undefined') {
    targetTenantId = process.env.REACT_APP_ROOT_TENANT_ID;
  }
  const message = JSON.stringify(location?.state?.message) ?? '';
  const [alertData, setAlertData] = useState({ open: (!!message), text: message, type: 'error' });
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState('');
  const [needsReload] = useState(false);
  const [reloadCounter, setReloadCounter] = useState(0);

  const login = async () => {
    setProgressMessage('Logging in...');
    setProgress(70);
    const form = document.createElement('form');
    form.setAttribute('method', 'GET');
    let apiHost = process.env.REACT_APP_API_HOST;
    if (apiHost.slice(-1) === '/') {
      apiHost = apiHost.slice(0, apiHost.length - 1);
    }
    form.setAttribute('action', `${process.env.REACT_APP_API_HOST}/${targetTenantId}/auth`);
    document.body.appendChild(form);
    form.submit();
    setProgress(70 + Math.floor(Math.random() * 5));
  };

  const wait = async (s) => new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, s * 1000);
  });

  useEffect(() => {
    const startup = async () => {
      setProgress(0);
      let dbWakingUp = true;
      let retry = false;
      for (let tries = 1; tries < 7; tries += 1) {
        setProgressMessage('Getting the system ready...');
        dbWakingUp = true;
        retry = false;
        const res = dbWakeUp();
        res.then((output) => {
          if (output.status === 200) {
            dbWakingUp = false;
          } else {
            retry = true;
          }
        });

        setProgress(tries * 10);
        let attempts = 0;
        while (dbWakingUp && !retry) {
          await delay(500); // 500ms;
          if (attempts % 2) {
            setProgressMessage((old) => `${old}.`);
          }
          attempts += 1;
        }
        if (retry) {
          continue;
        }
        if (!dbWakingUp) {
          break;
        }
      }
      if (dbWakingUp) {
        setProgressMessage('');
        setProgress(100);
        setAlertData({ open: true, text: 'Failed to connect to database.', type: 'error' });
        // setNeedsReload(true);
        // setReloadCounter(3);
      } else {
        await login();
        setProgress(100);
      }
    };
    startup();
  }, []);

  useEffect(() => {
    const countdown = async () => {
      while (reloadCounter > 0) {
        // eslint-disable-next-line no-await-in-loop
        await wait(1);
        setReloadCounter((old) => {
          if (old <= 0) {
            window.location.reload();
            return 0;
          }
          return old - 1;
        });
      }
    };
    if (needsReload) {
      countdown();
    }
  }, [needsReload]);

  return (
    <Box className="App">
      {(needsReload) ? (
        <Paper
          sx={{
            padding: '20% 30% 20% 30%',
          }}
          elevation={2}
        >
          <Typography variant="h5">
            Reloading Page in
            {' '}
            {' '}
            {needsReload && reloadCounter}
          </Typography>
        </Paper>
      ) : (
        <Box sx={{ width: '100%' }}>
          <Typography>{progressMessage}</Typography>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}

      <Alert
        type={alertData.type}
        open={alertData.open}
        text={alertData.text}
        onClose={() => setAlertData({ open: false })}
      />
    </Box>
  );
});

Login.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({ message: PropTypes.string }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Login;
