import {
  makeObservable, observable, action, computed,
} from 'mobx';
import { isValidJSON, parseActions } from '../utils/helpers';
import Workflow from './baseClasses/workflow';
// import Workflow from './baseClasses/workflow';

class WorkflowStore {
  workflows = [];

  currentWorkflow = undefined;

  currentWorkflowVersion = undefined;

  newWorkflow = {};

  constructor() {
    makeObservable(this, {
      workflows: observable,
      currentWorkflow: observable,
      currentWorkflowVersion: observable,
      newWorkflow: observable,
      setWorkflows: action,
      setCurrentWorkflow: action,
      setCurrentWorkflowVersion: action,
      setNewWorkflow: action,
      newWorkflowParams: computed,
    });
  }

  setWorkflows(data) {
    this.workflows = Object.keys(data).map((name) => new Workflow({ name, ...data[name] }));
  }

  setCurrentWorkflow(workflow) {
    this.currentWorkflow = workflow;
    this.currentWorkflowVersion = workflow.defaultVersion;
  }

  setCurrentWorkflowVersion(version) {
    this.currentWorkflowVersion = version;
  }

  setNewWorkflow(newWorkflow) {
    this.newWorkflow = { ...this.newWorkflow, ...newWorkflow };
  }

  get newWorkflowParams() {
    const inputs = new Set();
    const usersObj = {};
    if (typeof this.newWorkflow?.actions === 'object') {
      parseActions(this.newWorkflow?.actions, inputs, usersObj);
    } else if (isValidJSON(this.newWorkflow?.actions)) {
      parseActions(JSON.parse(this.newWorkflow?.actions), inputs, usersObj);
    }

    const users = Object.keys(usersObj).map((username) => ({ username, ...usersObj[username] }));
    return { inputs: Array.from(inputs), users, usersObj };
  }
}

const workflowStore = new WorkflowStore();

export default workflowStore;
