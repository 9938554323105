import axios from 'axios';
import {
  getTenantJwt,
  setTenantJwt,
  getTenantJwtExpiry,
  setTenantJwtExpiry,
  getTenantId,
} from './helpers';

const { Mutex } = require('async-mutex');

const mutexes = {
  refresh: new Mutex(),
  run: new Mutex(),
  tenant: new Mutex(),
  token: new Mutex(),
  user: new Mutex(),
  wakeup: new Mutex(),
  workflow: new Mutex(),
};

const hostname = process.env.REACT_APP_API_HOST;
const axiosInstance = axios.create({
  baseURL: hostname,
});

axiosInstance.interceptors.request.use(
  async (axiosConfig) => {
    const options = axiosConfig;

    const tenantId = getTenantId();
    const token = getTenantJwt(tenantId);

    // don't add tenant id for dbWakeUp
    if (options.url === '/wakeup') {
      return options;
    }

    // check if url already contains tenantId (for retry attempts)
    if (options.url.split('/')[0] !== tenantId) {
      options.url = tenantId + options.url;
    }

    if (token && !(options.url.includes('auth'))) {
      options.headers.authorization = `Bearer ${token}`;
    } else {
      delete options?.headers?.authorization;
    }
    return options;
  },

  (error) => Promise.reject(error),
);

export const getWebToken = async (type = 'auth', code = null) => {
  try {
    const release = await mutexes.token.acquire();
    try {
      let url = `/token?type=${type}`;
      if (type === 'auth') {
        url += `&code=${code}`;
      }
      const response = await axiosInstance.post(url);
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;

    if (status === 401) {
      // try to get a new non-expired JWT and retry the request
      const release = await mutexes.refresh.acquire();
      try {
        const now = Math.round(Date.now() / 1000);
        // check if the token has already been refreshed by a different request
        const tenantId = getTenantId();
        const tenantJwtExpiry = getTenantJwtExpiry(tenantId);
        if (now > tenantJwtExpiry) {
          const newTokRes = await getWebToken('refresh');
          if (newTokRes.status === 200) {
            setTenantJwt(tenantId, newTokRes.data.jwtToken);
            setTenantJwtExpiry(tenantId, newTokRes.data.expiry);
          }
        }
      } finally {
        release();
      }

      const retryRequest = await axiosInstance.request(originalRequest);
      return Promise.resolve(retryRequest);
    }

    if (status === 403) {
      const tenantId = getTenantId();
      const currentLocation = window.location;
      const currentHref = currentLocation.href.toLowerCase();
      let goToLogin = true;
      const loginTenantId = tenantId;

      if (currentHref.includes('/callback')) {
        goToLogin = false;
        // TODO: consider whether we want to default to root tenant
        // if (tenantId !== process.env.REACT_APP_ROOT_TENANT_ID) {
        //   loginTenantId = process.env.REACT_APP_ROOT_TENANT_ID;
        // } else {
        //   goToLogin = false;
        // }
      }

      if (goToLogin) {
        window.location = `/login/${loginTenantId}`;
      }
    }
    return Promise.reject(error);
  },
);

export const dbWakeUp = async () => {
  try {
    const release = await mutexes.wakeup.acquire();
    try {
      const response = await axiosInstance.get('/wakeup');
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const getUserData = async () => {
  try {
    const release = await mutexes.user.acquire();
    try {
      const response = await axiosInstance.get('/users');
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const createNewUser = async (user) => {
  try {
    const release = await mutexes.user.acquire();
    try {
      const response = await axiosInstance.post('/users', user);
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const editUser = async (user) => {
  try {
    const release = await mutexes.user.acquire();
    try {
      const response = await axiosInstance.put('/users', user);
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const deleteUser = async (user) => {
  try {
    const release = await mutexes.user.acquire();
    try {
      const response = await axiosInstance.delete('/users', { data: user });
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const getTenantData = async () => {
  try {
    const release = await mutexes.tenant.acquire();
    try {
      const response = await axiosInstance.get('/tenants');
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const verifyUser = async (user) => {
  try {
    const release = await mutexes.user.acquire();
    try {
      const response = await axiosInstance.post('/users/verify', user);
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const getWorkflows = async () => {
  try {
    const release = await mutexes.workflow.acquire();
    try {
      const response = await axiosInstance.get('/workflows');
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const setWorkflowActive = async (workflowId, versionId, isActive) => {
  try {
    const release = await mutexes.workflow.acquire();
    try {
      const response = await axiosInstance.put('/workflows/active', { workflowId, versionId, isActive });
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const setWorkflowDefault = async (workflowId, versionId, isDefault, isActive) => {
  try {
    const release = await mutexes.workflow.acquire();
    try {
      const response = await axiosInstance.put(
        '/workflows/default',
        {
          workflowId, versionId, isDefault, isActive,
        },
      );
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const deleteWorkflow = async (workflowId, versionId) => {
  try {
    const release = await mutexes.workflow.acquire();
    try {
      const response = await axiosInstance.delete('/workflows', { data: { workflowId, versionId } });
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const newWorkflow = async (name, version, isActive, actions, isDefault = 0) => {
  try {
    const release = await mutexes.workflow.acquire();
    try {
      const response = await axiosInstance.post('/workflows', {
        name, version, isActive, isDefault, actions,
      });
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const editWorkflow = async (workflowId, versionId, isActive, actions) => {
  try {
    const release = await mutexes.workflow.acquire();
    try {
      const response = await axiosInstance.put('/workflows', {
        workflowId, versionId, isActive, actions,
      });
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const runWorkflow = async (name, version, inputs) => {
  try {
    const release = await mutexes.run.acquire();
    try {
      const response = await axiosInstance.post('/workflows/run', {
        name, version, inputs, date: new Date(),
      });
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};

export const getWorkflowRunLogs = async (
  runId,
  all = false,
  startDate = undefined,
  endDate = undefined,
) => {
  try {
    const release = await mutexes.run.acquire();
    try {
      let uri = `/workflows/run?runId=${encodeURIComponent(runId)}&details=1`;

      if (all) {
        uri = '/workflows/run?details=0';

        if (startDate && endDate) {
          uri += [
            '',
            `startDate=${encodeURIComponent(startDate)}`,
            `endDate=${encodeURIComponent(endDate)}`,
          ].join('&');
        }
      }
      const response = await axiosInstance.get(uri);
      return response;
    } finally {
      release();
    }
  } catch (error) {
    return error.response || error;
  }
};
