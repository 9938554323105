/* eslint-disable react-hooks/exhaustive-deps */
import CachedIcon from '@mui/icons-material/Cached';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { beautifyDate } from '../utils/helpers';
import {
  createNewUser,
  deleteUser,
  editUser,
  getUserData,
  verifyUser,
} from '../utils/requests';
import InactiveChip from './InactiveChip';
import SplitButtonSelect from './SplitButtonSelect';
import Alert from './modals/Alert';
import DeleteAllModal from './modals/DeleteAllModal';
import LoadingSpinner from './modals/LoadingSpinner';
import ModifyUserModal from './modals/ModifyUserModal';
import ViewUserModal from './modals/ViewUserModal';

const Users = observer((props) => {
  const { userStore, tenantStore } = props;
  const [showInactive, setShowInactive] = useState(false);
  const [showViewUserModal, setShowViewUserModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [actionType, setActionType] = useState('New');
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(0);
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
  const [alertState, setAlertState] = useState({ open: false, text: 'placeholder', type: 'success' });

  const toggleInactive = () => {
    setShowInactive(!showInactive);
  };

  const refreshData = async () => {
    setShowLoadingSpinner((old) => old + 1);
    const res = await getUserData();
    if (res.status === 200) {
      userStore.setUsers(res.data);
    } else {
      setAlertState({
        open: true,
        text: `Error retrieving users: ${res.data}`,
        type: 'error',
      });
    }
    setShowLoadingSpinner((old) => old - 1);
  };

  useEffect(() => {
    if (!userStore.users.length) {
      refreshData();
    }
  }, [userStore]);

  const viewUser = (user) => {
    userStore.setCurrUser(user);
    setShowViewUserModal(true);
  };

  const newUser = () => {
    setActionType('New');
    userStore.clearCurrUser();
    setShowUserModal(true);
  };

  const closeModal = (name) => {
    switch (name) {
      case 'View':
        setShowViewUserModal(false);
        userStore.clearCurrUser();
        break;
      case 'New':
        setShowUserModal(false);
        userStore.clearCurrUser();
        break;
      case 'Edit':
        setShowUserModal(false);
        userStore.clearCurrUser();
        break;
      case 'View and open Edit':
        setActionType('Edit');
        setShowViewUserModal(false);
        setShowUserModal(true);
        break;
      default:
        setShowViewUserModal(false);
    }
  };

  const onSubmit = async (event, data) => {
    setShowLoadingSpinner((old) => old + 1);
    let res;
    if (event === 'delete') {
      res = await deleteUser({ username: data });
    } else if (event === 'New') {
      res = await createNewUser(data);
    } else if (event === 'Edit') {
      res = await editUser(data);
    } else if (event === 'Verify') {
      res = await verifyUser({ username: data });
      const success = Boolean(res.status === 200);
      // updates currUser and users array due to obj references
      userStore.currUser.setVerification(success, Date().toString());
      setShowLoadingSpinner((old) => old - 1);
      return true;
    }
    setShowLoadingSpinner((old) => old - 1);

    if (res.status === 200) {
      setAlertState({ open: true, text: 'Action was successful!', type: 'success' });
      await refreshData();
      return true;
    }

    // TODO: consider how to display error messages to front end in a better way
    if (event === 'New') { // new user
      if (res.data?.msg) {
        setAlertState({ open: true, text: res.data.msg, type: 'error' });
      } else {
        setAlertState({ open: true, text: 'Username and/or password does not match with existing Brightspace user.', type: 'error' });
      }
      await refreshData();
    } else if (event === 'Edit') {
      if (res.data?.msg) {
        setAlertState({ open: true, text: res.data.msg, type: 'error' });
      } else {
        setAlertState({ open: true, text: 'An error occurred. Check your inputs and try again.', type: 'error' });
      }
      await refreshData();
    } else {
      setAlertState({ open: true, text: 'An error occurred. Please check your inputs and try again later.', type: 'error' });
      await refreshData();
    }
    return false;
  };

  return (
    <Box>
      <Alert
        open={alertState.open}
        onClose={() => setAlertState({ ...alertState, open: false })}
        text={alertState.text}
        type={alertState.type}
      />

      <LoadingSpinner show={showLoadingSpinner > 0} />

      <ViewUserModal
        open={showViewUserModal}
        tenantStore={tenantStore}
        user={userStore.currUser}
        onSubmit={onSubmit}
        onClose={closeModal}
      />

      <DeleteAllModal
        text="Are you sure you want to delete all users?"
        open={showDeleteAllModal}
        data={userStore.users}
        onCancel={() => setShowDeleteAllModal(false)}
        onDone={() => {
          setShowDeleteAllModal(false);
        }}
        refresh={refreshData}
        type="users"
      />

      <ModifyUserModal
        open={showUserModal}
        tenantStore={tenantStore}
        user={userStore.currUser}
        onSubmit={onSubmit}
        onClose={closeModal}
        actionType={actionType}
      />
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="h4" component="h2">Workflow Users</Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              onClick={async () => { await refreshData(); }}
              sx={
                {
                  padding: '0px',
                  margin: '0px',
                }
              }
            >
              <CachedIcon style={{ padding: '10px' }} />
            </IconButton>
          </Box>
        </Grid>
        <Grid
          container
          sx={
            {
              paddingBottom: '1em',
            }
          }
        >
          <Grid item xs={8}>
            <FormGroup row>
              <FormControlLabel
                control={(
                  <Switch
                    checked={showInactive}
                    onChange={toggleInactive}
                    name="inactive"
                    color="primary"
                  />
                )}
                label="Show Inactive"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <SplitButtonSelect
                onClick={newUser}
                options={{
                  'Delete All Users': {
                    func: () => { setShowDeleteAllModal(true); },
                    color: 'red',
                  },
                }}
              >
                Add User
              </SplitButtonSelect>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Stack>
        {userStore.users && ((userStore.users.length) ? (userStore.users.map((user) => {
          if (user.isActive || showInactive) {
            return (
              <Paper
                sx={
                  {
                    border: '1px solid darkgray',
                    margin: '10px 0 10px 0',
                    padding: '10px',
                  }
                }
                key={user.username}
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <Button color={user.noPass ? 'warning' : 'primary'} onClick={() => viewUser(user)}>
                      {user.username}
                    </Button>
                    { user.noPass && (
                      <Tooltip title="Note: Workflow users without a password cannot be used with some action types (e.g. quizAttempt)" placement="right" arrow>
                        <ErrorOutlineOutlinedIcon style={{ marginLeft: 3, marginBottom: -5 }} />
                      </Tooltip>
                    )}
                    { InactiveChip(user.isActive) }
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      component="span"
                      sx={{
                        fontSize: 'small',
                        paddingLeft: '1em',
                      }}
                    >
                      (
                      {`Modified: ${beautifyDate(user.dateLastModified)}`}
                      )
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ paddingLeft: '15px' }}>
                  <Typography sx={{ fontStyle: 'italic', wordBreak: 'break-all' }}>
                    {user.description}
                  </Typography>
                  {
                    (user.verification)
                      ? (
                        <Typography
                          component="span"
                          color={
                            (user.verification.success)
                              ? 'success'
                              : 'error'
                          }
                        >
                          {
                            (user.verification.success)
                              ? <CheckIcon style={{ verticalAlign: 'middle' }} />
                              : <CloseIcon style={{ verticalAlign: 'middle' }} />
                          }
                          Verification:
                          {' '}
                          {user.verification.success ? 'Success' : 'Failure'}
                          {' '}
                          (
                          {beautifyDate(user.verification.time, 'short')}
                          )
                        </Typography>
                      )
                      : null
                  }
                </Box>
              </Paper>
            );
          }
          return null;
        }))
          : (
            <Box sx={{ width: '100%' }} align="center">
              <Box
                sx={{
                  marginTop: '10%',
                  padding: '20px',
                  border: 'solid lightgray 1px',
                  width: '80%',
                }}
                align="center"
              >
                <Typography color="#606060">
                  No users to show
                </Typography>
              </Box>
            </Box>
          ))}
        {(!showInactive
        && userStore.users.length
        && userStore.users.filter((user) => user.isActive).length === 0) ? (
          <Box sx={{ width: '100%' }} align="center">
            <Box
              sx={{
                marginTop: '10%',
                padding: '20px',
                border: 'solid lightgray 1px',
                width: '80%',
              }}
              align="center"
            >
              <Typography color="#606060">
                No active users to show
              </Typography>
            </Box>
          </Box>
          ) : ''}
      </Stack>
    </Box>
  );
});

export default Users;
