import React from 'react';
import {
  Backdrop,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';

function LoadingSpinner({ show }) {
  return (
    <Backdrop
      sx={{
        zIndex: 9999,
        color: '#fff',
      }}
      open={show}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

LoadingSpinner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default LoadingSpinner;
