/* eslint-disable max-len */
import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Container,
  Grid,
} from '@mui/material';
import Navbar from './Navbar';

import '../styles/manage.css';

import Users from './Users';
import Workflows from './Workflows';

const Manage = observer((props) => {
  const { store } = props;

  const sxPanel = {
    border: '1px solid darkgray',
    padding: '10px',
    margin: '5px',
    height: '80vh',
    overflow: 'auto',
  };
  return (
    <Box>
      <Navbar page="manage" tenantStore={store.tenantStore} workflowStore={store.workflowStore} userStore={store.userStore} />
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Box sx={sxPanel}>
              <Workflows tenantStore={store.tenantStore} workflowStore={store.workflowStore} userStore={store.userStore} />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={sxPanel}>
              <Users tenantStore={store.tenantStore} userStore={store.userStore} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});

export default Manage;
