import {
  makeObservable, observable,
} from 'mobx';
import WorkflowVersion from './workflowVersion';

class Workflow {
  name = '';

  versions = [];

  defaultVersion = '';

  constructor(data) {
    makeObservable(this, {
      name: observable,
      versions: observable,
      defaultVersion: observable,
    });
    this.name = data.name;
    this.versions = data.versions.map((version) => {
      const workflowVersion = new WorkflowVersion(version);
      if (version.isDefault) this.defaultVersion = workflowVersion;
      return workflowVersion;
    });
  }
}

export default Workflow;
