import {
  makeObservable, observable,
} from 'mobx';

class Tenant {
  awsRegion = '';

  brightspaceDomain = '';

  enrollmentRequirement = '';

  isActive = null;

  name = '';

  oAuthClientDomain = '';

  oAuthClientId = '';

  tenantId = '';

  isValid = '';

  dateLastAccessed = '';

  useDdb = false;

  constructor(data) {
    makeObservable(this, {
      awsRegion: observable,
      brightspaceDomain: observable,
      enrollmentRequirement: observable,
      isActive: observable,
      name: observable,
      oAuthClientDomain: observable,
      oAuthClientId: observable,
      tenantId: observable,
      isValid: observable,
      dateLastAccessed: observable,
      useDdb: observable,
    });
    this.awsRegion = data.awsRegion;
    this.brightspaceDomain = data.brightspaceDomain;
    this.enrollmentRequirement = data.enrollmentRequirement;
    this.isActive = data.isActive;
    this.name = data.name;
    this.oAuthClientDomain = data.oAuthClientDomain;
    this.oAuthClientId = data.oAuthClientId;
    this.tenantId = data.tenantId;
    this.isValid = data.isValid;
    this.dateLastAccessed = data.dateLastAccessed;
    this.useDdb = data.useDdb;
  }
}

export default Tenant;
