import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { getWorkflowRunLogs } from '../utils/requests';

import LoadingSpinner from './modals/LoadingSpinner';
import Alert from './modals/Alert';
import Navbar from './Navbar';
import StyledTableCell from './StyledTableCell';

import '../styles/manage.css';
import { beautifyDate, getRunIdForDisplay } from '../utils/helpers';
import IndividualWorkflowRunLog from './IndividualWorkflowRunLog';

const AllWorkflowRunLogs = observer((props) => {
  const { tenantStore } = props;
  const [allWorkflowRunData, setAllWorkflowRunData] = useState({});
  const [allWorkflowRunDataLogs, setAllWorkflowRunDataLogs] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState('');
  const [detailedWorkflowRunData, setDetailedWorkflowRunData] = useState({
    actionResults: [],
    name: '',
    version: '',
    polled: false,
    startDate: null,
    endDate: null,
    overallStatusMessage: null,
    secondsTillRetry: null,
  });

  const [detailedWorkflowModal, setDetailedWorkflowModal] = useState(false);
  const [logsStartDate, setLogsStartDate] = useState('');
  const [logsStartDateValid, setLogsStartDateValid] = useState(true);

  const [logsEndDate, setLogsEndDate] = useState('');
  const [logsEndDateValid, setLogsEndDateValid] = useState(true);

  const [customLogDates, setCustomLogDates] = useState(true);

  const [searchError, setSearchError] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);

  const [loadWorkflowRuns, setLoadWorkflowRuns] = useState(false);

  const setErrorMessage = (msg) => {
    setSearchErrorMessage(msg);
    setSearchError(true);
  };

  const resetLogDates = () => {
    const dt = new Date();
    setLogsEndDate(dt.toISOString().split('T')[0]);

    const dtStart = new Date(dt);
    dtStart.setDate(dtStart.getDate() - 14);
    setLogsStartDate(dtStart.toISOString().split('T')[0]);
    setLoadWorkflowRuns(true);
  };

  useEffect(() => {
    resetLogDates();
    setLogsEndDateValid(true);
    setLogsStartDateValid(true);
    setShowLoadingSpinner(false);
  }, []);

  useEffect(() => {
    const getAllWorkflowRunData = async () => {
      setShowLoadingSpinner(true);
      const startDate = (customLogDates) ? logsStartDate : undefined;
      const endDate = (customLogDates) ? logsEndDate : undefined;

      const res = await getWorkflowRunLogs(null, true, startDate, endDate);
      if (res.status === 200) {
        const workflowNames = new Set();
        const workflowDataObj = {};
        const allWorkflowDataLogs = [];
        const { data } = res;
        data.forEach((log) => workflowNames.add(log.workflowName));
        workflowNames.forEach((name) => { workflowDataObj[name] = []; });
        data.forEach((log) => {
          const { workflowName } = log;
          workflowDataObj[workflowName].push(log);
          allWorkflowDataLogs.push(log);
        });

        setAllWorkflowRunData(workflowDataObj);
        setAllWorkflowRunDataLogs(allWorkflowDataLogs);
      } else {
        const { data } = res;
        setErrorMessage(data.msg);
      }
      setShowLoadingSpinner(false);
      setLoadWorkflowRuns(false);
    };

    if (loadWorkflowRuns) {
      getAllWorkflowRunData();
    }
  }, [loadWorkflowRuns, customLogDates, logsEndDate, logsStartDate]);

  const getDetailedData = async (runId) => {
    const res = await getWorkflowRunLogs(runId);
    if (res.status === 200) {
      const {
        actionResults, workflowName, version, startDate, endDate, overallStatusMessage,
        actionsToComplete, criticalFailedActions, successfulActions, failedActions, skippedActions,
      } = res.data[0];

      const completedActions = criticalFailedActions
                                + successfulActions
                                + failedActions
                                + skippedActions;

      setDetailedWorkflowRunData({
        actionResults: actionResults || [],
        workflowName,
        version,
        polled: true,
        startDate,
        endDate,
        overallStatusMessage,
        runId,
        actionsToComplete,
        completedActions,
        criticalFailedActions,
        successfulActions,
        failedActions,
        skippedActions,
      });
      setDetailedWorkflowModal(true);
    }
  };

  const handleChange = (e) => {
    const targetValue = e.target.value;

    const dtTarget = new Date(targetValue);
    // eslint-disable-next-line no-self-compare
    const dateValid = (dtTarget.getTime() === dtTarget.getTime());
    if (e.target.id === 'end-date-input') {
      setLogsEndDate(targetValue);
      setLogsEndDateValid(dateValid);
    } else {
      setLogsStartDate(targetValue);
      setLogsStartDateValid(dateValid);
    }
  };

  const doSearch = async () => {
    if (!logsEndDateValid || !logsStartDateValid) {
      setErrorMessage('Invalid search date(s)');
      return false;
    }

    const dtEndDate = new Date(logsEndDate);
    const dtStartDate = new Date(logsStartDate);

    if (dtEndDate < dtStartDate) {
      setErrorMessage('End date cannot be before start date');
      return false;
    }

    const diff = dtEndDate.getTime() - dtStartDate.getTime();
    const MS_IN_DAY = 24 * 60 * 60 * 1000;
    if ((diff / MS_IN_DAY) > 92) {
      setErrorMessage('Cannot search for a date range larger than a quarter');
      return false;
    }

    setLoadWorkflowRuns(true);
    return true;
  };

  return (
    <div style={{ height: '100vh' }}>
      <Alert
        open={searchError}
        onClose={() => setSearchError(false)}
        text={searchErrorMessage}
        type="error"
      />
      <LoadingSpinner show={showLoadingSpinner} />
      <Navbar page="logs" tenantStore={tenantStore} />
      <div className="width-limiter">
        <div className="panel-container" style={{ height: 'unset' }}>

          <Dialog open={detailedWorkflowModal} disableEscapeKeyDown maxWidth="xl">
            <DialogContent style={{ height: '75vh', width: '75vw' }}>
              <IndividualWorkflowRunLog workflowRunData={detailedWorkflowRunData} />
            </DialogContent>
            <DialogActions style={{
              display: 'flex', justifyContent: 'center', borderTop: '1px solid', marginTop: 20, padding: 16,
            }}
            >
              <div>
                <Button variant="contained" color="primary" onClick={() => setDetailedWorkflowModal(false)} style={{ width: 100 }}>
                  Close
                </Button>
              </div>

            </DialogActions>
          </Dialog>
          <Grid container style={{ width: '50%', margin: '20px' }} alignContent="center" spacing={4}>
            <Grid item xs={12}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel htmlFor="workflow-select">Workflow</InputLabel>
                <Select
                  value={selectedWorkflow || ''}
                  onChange={(e) => setSelectedWorkflow(e.target.value)}
                  label="Workflow"
                  id="workflow-select"
                >
                  <MenuItem value="">
                    <em>All Workflows</em>
                  </MenuItem>
                  {Object.keys(allWorkflowRunData).map((workflowName) => (
                    <MenuItem value={workflowName} key={workflowName}>
                      {workflowName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {
                (!customLogDates)
                  ? (
                    <Grid container alignItems="center">
                      <Grid item xs={10}>
                        <Typography>Showing the previous 14 days</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Button onClick={() => setCustomLogDates(true)}>Custom</Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <FormControl variant="outlined">
                          <InputLabel htmlFor="start-date-input">Start Date (YYYY-MM-DD)</InputLabel>
                          <Input
                            id="start-date-input"
                            value={logsStartDate}
                            onChange={handleChange}
                            sx={(!logsStartDateValid) ? { color: 'red' } : {}}
                            error={(!logsStartDateValid)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={5}>
                        <FormControl variant="outlined">
                          <InputLabel htmlFor="end-date-input">End Date (YYYY-MM-DD)</InputLabel>
                          <Input
                            id="end-date-input"
                            value={logsEndDate}
                            maxLength={10}
                            onChange={handleChange}
                            sx={(!logsEndDateValid) ? { color: 'red' } : {}}
                            error={(!logsEndDateValid)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <Button variant="contained" onClick={() => doSearch()}>Search</Button>
                      </Grid>
                    </Grid>
                  )
              }
            </Grid>
          </Grid>
          {(selectedWorkflow === '' || (Boolean(selectedWorkflow) && Boolean(allWorkflowRunData[selectedWorkflow]))) && (
            <Paper sx={{ width: '80%' }}>
              <Typography sx={{ paddingLeft: '3px' }}>
                <strong>Results returned:</strong>
                {
                  ` ${
                    ((selectedWorkflow === '')
                      ? allWorkflowRunDataLogs
                      : allWorkflowRunData[selectedWorkflow]).length
                  } Workflow Runs`
                }
              </Typography>
              <TableContainer sx={{ width: '100%', maxHeight: '70vh' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell size="5px">
                        #
                      </StyledTableCell>
                      <StyledTableCell>
                        Run Id
                      </StyledTableCell>
                      <StyledTableCell>
                        Workflow (Version)
                      </StyledTableCell>
                      <StyledTableCell>
                        # of Actions
                      </StyledTableCell>
                      <StyledTableCell>
                        Status
                      </StyledTableCell>
                      <StyledTableCell>
                        Start Date
                      </StyledTableCell>
                      <StyledTableCell>
                        Duration (s)
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(selectedWorkflow === '' ? allWorkflowRunDataLogs : allWorkflowRunData[selectedWorkflow]).map((run, index) => (
                      <TableRow
                        key={run.runId}
                        onClick={() => getDetailedData(run.runId)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: '#EEF !important',
                          },
                          '&:nth-of-type(odd)': {
                            backgroundColor: 'action.hover',
                          },
                        }}
                      >
                        <TableCell sx={{ width: '5px' }}>{index + 1}</TableCell>
                        <TableCell className="table-cell" align="center">
                          {/* RunID */}
                          {getRunIdForDisplay(run.runId)}
                        </TableCell>
                        <TableCell className="table-cell" align="center">
                          {/* Workflow */}
                          {`${run.workflowName} (${run.version})`}
                        </TableCell>
                        <TableCell className="table-cell" align="center">
                          {/* # of Actions */}
                          {/* {numActions > 0 ? numActions : 'dunno yet'} */}
                          {run.actionsToComplete}
                        </TableCell>
                        <TableCell className="table-cell" align="center">
                          {/* Status */}
                          {run.overallStatusMessage}
                          <div>
                            {run.criticalFailedActions
                              ? (
                                <div>
                                  Critical Failures:
                                  {' '}
                                  {run.criticalFailedActions}
                                </div>
                              )
                              : ''}
                            {run.overallStatusMessage !== 'Success' && run.successfulActions
                              ? (
                                <div>
                                  Successes:
                                  {' '}
                                  {run.successfulActions}
                                </div>
                              )
                              : ''}
                            {run.failedActions
                              ? (
                                <div>
                                  Failures:
                                  {' '}
                                  {run.failedActions}
                                </div>
                              ) : ''}
                            {run.skippedActions
                              ? (
                                <div>
                                  Skipped:
                                  {' '}
                                  {run.skippedActions}
                                </div>
                              ) : ''}
                          </div>
                        </TableCell>
                        <TableCell className="table-cell" align="center">
                          {/* Start Date */}
                          {run.startDate ? beautifyDate(run.startDate) : '-'}
                        </TableCell>
                        <TableCell className="table-cell" align="center">
                          {/* Duration (s) */}
                          {run.endDate ? Math.ceil((new Date(run.endDate) - new Date(run.startDate)) / 1000) : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}

        </div>
      </div>
    </div>
  );
});

export default AllWorkflowRunLogs;
