import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';

function ConfirmModal(props) {
  const {
    open, text, onCancel, onConfirm,
  } = props;

  return (
    <Dialog open={open} disableEscapeKeyDown maxWidth="sm">
      <DialogContent sx={{ minHeight: 150 }}>
        <Box sx={{ wordBreak: 'break-all' }}>
          {text}
        </Box>
      </DialogContent>
      <DialogActions style={
        {
          display: 'flex', justifyContent: 'center', borderTop: '1px solid', marginTop: 20, padding: 16,
        }
      }
      >
        <Box>
          <Button variant="contained" color="primary" autoFocus onClick={onConfirm} style={{ margin: '0 5px 0 5px', width: 100 }}>
            Confirm
          </Button>
          <Button variant="contained" color="secondary" onClick={onCancel} style={{ margin: '0 5px 0 5px', width: 100 }}>
            Cancel
          </Button>

        </Box>
      </DialogActions>
    </Dialog>
  );
}

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmModal;
