/* eslint-disable no-nested-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import PropTypes, { array } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { beautifyDate, sleep } from '../../utils/helpers';
import {
  deleteUser,
  deleteWorkflow,
  getWorkflows,
  setWorkflowDefault,
} from '../../utils/requests';

// length of time (in ms) to sleep between API calls to make it easier to see what is happening
const sleepTime = 250;

function DeleteAllModal(props) {
  const {
    open, text, data, onCancel, onDone, type, refresh,
  } = props;

  const bottomRef = useRef(null);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [logs]);

  const wait = async (sec) => new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, sec * 1000);
  });

  const closeModal = async () => {
    await onDone();
    await wait(0.5);
    setDone(false);
  };

  const handleClose = (event, reason) => {
    if (!loading) {
      closeModal();
    }
  };

  const addToLogs = (log) => {
    setLogs((prevLogs) => [...prevLogs, log]);
  };

  const onSubmitWorkflows = async () => {
    setLogs([]);
    setLoading(true);
    let firstApiRequest = true;
    // Go through all workflows in the workflows
    for (let i = 0; i < data.length; i += 1) {
      const curWorkflow = data[i];
      // for each workflow delete every version
      for (let ii = 0; ii < curWorkflow.versions.length; ii += 1) {
        const version = curWorkflow.versions[ii];
        if (version.isDefault) {
          if (!firstApiRequest) {
            await sleep(sleepTime);
          } else {
            firstApiRequest = false;
          }
          // remove default version as it stops the delete
          const defaultWorkflowResponse = await setWorkflowDefault(
            version.workflowId,
            version.versionId,
            0,
            0,
          );
          const success = (defaultWorkflowResponse.status === 200);
          const primary = `${(success) ? 'Removed Default:' : 'Error Removing Default:'} ${curWorkflow.name}`;
          const secondary = `${version.version} - ${beautifyDate(Date.now())}`;
          const logEntry = { success, primary, secondary };
          addToLogs(logEntry);
        }

        if (!firstApiRequest) {
          await sleep(sleepTime);
        } else {
          firstApiRequest = false;
        }
        const deleteWorkflowResponse = await deleteWorkflow(
          version.workflowId,
          version.versionId,
        );
        const success = (deleteWorkflowResponse.status === 200);
        const primary = `${(success) ? 'Deleted:' : 'ERROR Deleting:'} ${curWorkflow.name}`;
        const secondary = `${version.version} - ${beautifyDate(Date.now())}`;
        const logEntry = { success, primary, secondary };
        addToLogs(logEntry);
      }
    }
    setLoading(false);
    setDone(true);
    await refresh();
  };

  const onSubmitUsers = async () => {
    setLoading(true);
    setLogs([]);
    for (let i = 0; i < data.length; i += 1) {
      const curUser = data[i];

      if (i > 0) {
        await sleep(sleepTime);
      }
      const deleteResponse = await deleteUser({ username: curUser.username });
      const success = (deleteResponse.status === 200);
      const primary = `${(success) ? 'Deleted:' : 'Error:'} ${curUser.username}`;
      const secondary = `${beautifyDate(Date.now())}`;
      const logEntry = { success, primary, secondary };
      addToLogs(logEntry);
    }
    setLoading(false);
    setDone(true);
    await refresh();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      {(loading || done) ? (
        <DialogContent sx={{ minHeight: 150 }}>
          <Typography variant="h6">Deletion Logs</Typography>
          <Box>
            <Grid>
              <List>
                {logs.map((logValue) => (
                  <ListItem key={`${logValue.primary}:${logValue.secondary}`}>
                    <ListItemText
                      primary={logValue.primary}
                      secondary={logValue.secondary}
                      primaryTypographyProps={{
                        color: (logValue.success) ? 'primary' : 'error',
                      }}
                    />
                  </ListItem>
                ))}
                {loading ? (
                  <ListItem sx={{
                    justifyContent: 'center',
                  }}
                  >
                    <CircularProgress />
                  </ListItem>
                )
                  : ''}
                <ListItem ref={bottomRef} />
              </List>
            </Grid>
          </Box>
        </DialogContent>
      )
        : (
          <DialogContent>
            <Typography>{text}</Typography>
          </DialogContent>
        )}
      <DialogActions style={
        {
          display: 'flex', justifyContent: 'center', borderTop: '1px solid', marginTop: 20, padding: 16,
        }
      }
      >
        <Box>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            autoFocus
            onClick={done ? (async () => { await closeModal(); }) : ((type === 'workflows') ? onSubmitWorkflows : onSubmitUsers)}
            style={{ margin: '0 5px 0 5px', width: 100 }}
          >
            {(done) ? 'Close' : 'Confirm'}
          </Button>
          {(done || loading) ? '' : (
            <Button variant="contained" color="secondary" onClick={onCancel} style={{ margin: '0 5px 0 5px', width: 100 }}>
              Cancel
            </Button>
          )}

        </Box>
      </DialogActions>
    </Dialog>
  );
}

DeleteAllModal.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default DeleteAllModal;
