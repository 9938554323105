/* eslint-disable no-console */
import React from 'react';
import { TableRow } from '@mui/material';
import PropTypes from 'prop-types';

function HoverableTableRow(props) {
  const { children } = props;
  return (
    <TableRow
      sx={{
        '&:nth-of-type(odd)': {
          backgroundColor: 'action.hover',
        },
      }}
    >
      {children}
    </TableRow>
  );
}

HoverableTableRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HoverableTableRow;
