import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import 'ace-builds';
import 'ace-builds/webpack-resolver';
import AceEditor from 'react-ace';

import HoverableTableRow from './HoverableTableRow';
import StyledTableCell from './StyledTableCell';

import { shouldStringify } from '../utils/helpers';

function WorkflowPreview({ actions = [] }) {
  const renderDropdowns = (action, tabNum = 0, index = undefined, path = '/') => (
    <div key={action + index + tabNum}>
      <Accordion style={{ border: '1px solid', margin: '10px 0 10px 0', marginLeft: `${tabNum * 20}px` }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Tooltip title={`The path for this is action is: ${path}${index}`}>
            <span style={{ color: '#004040' }}>{`(${path}${index})`}</span>
          </Tooltip>
          &nbsp;
          <span>{`${action.type}`}</span>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, margin: 0 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  Attributes
                </StyledTableCell>
                <StyledTableCell>
                  Values
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(action).map((key) => {
                if (key === 'children') return null;
                return (
                  // eslint-disable-next-line max-len
                  <HoverableTableRow key={new Date().getTime() + key}>
                    <TableCell key={`unique1${key}`} align="center" className="table-cell">
                      {key}
                    </TableCell>
                    <TableCell key={`unique2${key}`} align="center" className="table-cell">
                      {
                        shouldStringify(action[key])
                          ? (
                            <AceEditor
                              mode="json"
                              theme="github"
                              maxLines={Infinity}
                              readOnly
                              value={
                                JSON.stringify(
                                  action[key],
                                  null,
                                  2,
                                )
                              }
                            />
                          ) : (
                            JSON.stringify(action[key])
                          )
                      }
                    </TableCell>
                  </HoverableTableRow>
                );
              })}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      {action.children?.map((child, ind) => renderDropdowns(child, tabNum + 1, ind, [path, index, '/'].join('')))}
    </div>
  );
  return (
    <div style={{
      border: '1px solid', height: '93%', overflow: 'auto', padding: '0 10px', backgroundColor: '#EEE',
    }}
    >
      {actions?.map((action, index) => renderDropdowns(action, 0, index))}
    </div>
  );
}

WorkflowPreview.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape),
};

WorkflowPreview.defaultProps = {
  actions: [],
};

export default WorkflowPreview;
