/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import CachedIcon from '@mui/icons-material/Cached';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { beautifyDate } from '../utils/helpers';
import {
  deleteWorkflow,
  editWorkflow,
  getWorkflows,
  newWorkflow,
  setWorkflowActive,
  setWorkflowDefault,
} from '../utils/requests';

import InactiveChip from './InactiveChip';
import SplitButtonSelect from './SplitButtonSelect';
import Alert from './modals/Alert';
import CreateWorkflowModal from './modals/CreateWorkflowModal';
import DeleteAllModal from './modals/DeleteAllModal';
import LoadingSpinner from './modals/LoadingSpinner';
import ViewWorkflowModal from './modals/ViewWorkflowModal';

const Workflows = observer((props) => {
  const { workflowStore, tenantStore, userStore } = props;
  const [showInactive, setShowInactive] = useState(false);
  const [showNewWorkflowModal, setShowNewWorkflowModal] = useState(false);
  const [showViewWorkflowModal, setShowViewWorkflowModal] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(0);
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);

  const [alertState, setAlertState] = useState({ open: false, text: '', type: 'success' });
  const [actionType, setActionType] = useState('New');

  const refreshData = async () => {
    setShowLoadingSpinner((old) => old + 1);
    const res = await getWorkflows();
    if (res.status === 200) {
      workflowStore.setWorkflows(res.data);
    } else {
      setAlertState({
        open: true,
        text: `Error retrieving workflows: ${res.data}`,
        type: 'error',
      });
    }
    setShowLoadingSpinner((old) => old - 1);
  };

  useEffect(() => {
    if (!workflowStore.workflows.length) {
      refreshData();
    }
  }, [workflowStore]);

  const handleClose = (type = '') => {
    switch (type) {
      case 'New':
        setShowNewWorkflowModal(false);
        setActionType('New');
        break;
      case 'View':
        setShowViewWorkflowModal(false);
        break;
      case 'View and Edit':
        setShowViewWorkflowModal(false);
        setShowNewWorkflowModal(true);
        setActionType('Edit');
        workflowStore.setNewWorkflow({
          workflowId: workflowStore.currentWorkflowVersion.workflowId,
          versionId: workflowStore.currentWorkflowVersion.versionId,
          name: workflowStore.currentWorkflow.name,
          version: workflowStore.currentWorkflowVersion.version,
          isActive: workflowStore.currentWorkflowVersion.isActive,
          actions: JSON.stringify(workflowStore.currentWorkflowVersion.actions, null, 2),
        });
        break;
      case 'View and Copy':
        setShowViewWorkflowModal(false);
        setShowNewWorkflowModal(true);
        setActionType('New');
        workflowStore.setNewWorkflow({
          name: workflowStore.currentWorkflow.name,
          isActive: workflowStore.currentWorkflowVersion.isActive,
          actions: JSON.stringify(workflowStore.currentWorkflowVersion.actions, null, 2),
        });
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (type = '', data = undefined) => {
    setShowLoadingSpinner((old) => old + 1);
    let returnData = {};
    let res;
    switch (type) {
      case 'Active':
        res = await setWorkflowActive(
          workflowStore.currentWorkflowVersion.workflowId,
          workflowStore.currentWorkflowVersion.versionId,
          data.isActive,
        );
        if (res.status === 200) {
          setAlertState({ open: true, text: `Workflow set to ${data.isActive ? 'active' : 'inactive'} successfully!`, type: 'success' });
          setShowViewWorkflowModal(false);
          await refreshData();
        } else {
          setAlertState({ open: true, text: 'There was an error updating workflow isActive state, please try again later', type: 'error' });
        }
        break;
      case 'Default':
        res = await setWorkflowDefault(
          workflowStore.currentWorkflowVersion.workflowId,
          workflowStore.currentWorkflowVersion.versionId,
          data.isDefault,
          workflowStore.currentWorkflowVersion.isActive,
        );

        if (res.status === 200) {
          setAlertState({ open: true, text: 'Workflow default state set successfully!', type: 'success' });
          setShowViewWorkflowModal(false);

          await refreshData();
        } else {
          setAlertState({ open: true, text: 'There was an error updating workflow default state, please try again later', type: 'error' });
        }
        break;

      case 'Delete':
        res = await deleteWorkflow(
          workflowStore.currentWorkflowVersion.workflowId,
          workflowStore.currentWorkflowVersion.versionId,
        );

        if (res.status === 200) {
          setAlertState({ open: true, text: 'Workflow deleted successfully!', type: 'success' });
          setShowViewWorkflowModal(false);
          await refreshData();
        } else {
          setAlertState({ open: true, text: 'There was an error trying to delete the workflow, please try again later', type: 'error' });
        }
        break;
      case 'New':
        res = await newWorkflow(
          workflowStore.newWorkflow.name,
          workflowStore.newWorkflow.version,
          workflowStore.newWorkflow.isActive,
          workflowStore.newWorkflow.actions,
        );
        if (res.status === 200) {
          setAlertState({ open: true, text: 'Workflow created successfully!', type: 'success' });
          setShowNewWorkflowModal(false);
          await refreshData();
        } else if (typeof res.data === 'object' && res.data.length) {
          returnData = res.data;
        } else {
          setAlertState({ open: true, text: `There was an error trying to create the workflow, message - ${res.data.msg}`, type: 'error' });
        }
        break;
      case 'Edit':
        res = await editWorkflow(
          workflowStore.newWorkflow.workflowId,
          workflowStore.newWorkflow.versionId,
          workflowStore.newWorkflow.isActive,
          workflowStore.newWorkflow.actions,
        );
        if (res.status === 200) {
          setAlertState({ open: true, text: 'Workflow Edited successfully!', type: 'success' });
          setShowNewWorkflowModal(false);
          await refreshData();
        } else if (typeof res.data === 'object' && res.data.length) {
          returnData = res.data;
        } else {
          setAlertState({
            open: true,
            text: `There was an error while trying to edit the workflow, message - ${res.data.msg}`,
            type: 'error',
          });
        }
        break;
      default:
        break;
    }
    setShowLoadingSpinner((old) => old - 1);
    return returnData;
  };

  const createNewWorkflow = () => {
    setShowNewWorkflowModal(true);
    workflowStore.setNewWorkflow({
      name: '',
      version: '',
      isActive: true,
      actions: '[\n\n]',
    });
  };

  const viewWorkflow = (workflow, version, e) => {
    e.stopPropagation();
    workflowStore.setCurrentWorkflow(workflow);
    workflowStore.setCurrentWorkflowVersion(version);
    setShowViewWorkflowModal(true);
  };

  return (
    <Box>
      <Alert
        open={alertState.open}
        text={alertState.text}
        type={alertState.type}
        onClose={() => setAlertState({ ...alertState, open: false })}

      />

      <LoadingSpinner show={showLoadingSpinner > 0} />

      <CreateWorkflowModal
        workflowStore={workflowStore}
        tenantStore={tenantStore}
        open={showNewWorkflowModal}
        onClose={handleClose}
        onSubmit={handleSubmit}
        actionType={actionType}
        userStore={userStore}
      />

      <DeleteAllModal
        text="Are you sure you want to delete all workflows?"
        open={showDeleteAllModal}
        data={workflowStore.workflows}
        onCancel={() => setShowDeleteAllModal(false)}
        onDone={() => {
          setShowDeleteAllModal(false);
        }}
        refresh={refreshData}
        type="workflows"
      />

      <ViewWorkflowModal
        workflowStore={workflowStore}
        userStore={userStore}
        tenantStore={tenantStore}
        open={showViewWorkflowModal}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="h4" component="h2">Workflows</Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              onClick={refreshData}
              sx={
                {
                  padding: '0px',
                  margin: '0px',
                }
              }
            >
              <CachedIcon style={{ padding: '10px' }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={
          {
            paddingBottom: '1em',
          }
        }
      >
        <Grid item xs={8} container>
          <FormGroup row>
            <FormControlLabel
              control={(
                <Switch
                  checked={showInactive}
                  onChange={() => setShowInactive(!showInactive)}
                  name="inactive"
                  color="primary"
                />
              )}
              label="Show Inactive"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <SplitButtonSelect
              onClick={() => createNewWorkflow()}
              options={{
                'Delete All Workflows': {
                  func: () => setShowDeleteAllModal(true),
                  color: 'red',
                },
              }}
            >
              Create&nbsp;WorkFlow
            </SplitButtonSelect>
          </Box>
        </Grid>
      </Grid>
      <Grid>
        <Stack>
          {workflowStore.workflows.length ? workflowStore.workflows.map((workflow) => {
            let noVisibleVersions = true;
            return (
              <Paper
                sx={
                {
                  border: '1px solid darkgray',
                  margin: '10px 0 10px 0',
                  padding: '10px',
                }
              }
                key={workflow.name}
              >
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: 'bold' }}
                  gutterBottom
                >
                  {workflow.name}
                </Typography>
                <Typography>Versions:</Typography>
                <Grid container spacing={1} alignItems="center">
                  {workflow.versions.map((version) => {
                    let versionButtonColour;
                    if (version.isDefault) {
                      versionButtonColour = 'primary';
                    } else {
                      versionButtonColour = 'info';
                    }

                    if (!version.isActive && !showInactive) {
                      return null;
                    }
                    noVisibleVersions = false;

                    return (
                      <React.Fragment key={workflow.name + version.version}>
                        <Grid item xs={12} sm={2}>
                          <Button
                            sx={{ width: '100%' }}
                            variant={
                            (version.isDefault === 1)
                              ? 'contained'
                              : 'outlined'
                          }
                            onClick={(e) => viewWorkflow(workflow, version, e)}
                            onKeyPress={(e) => viewWorkflow(workflow, version, e)}
                            color={versionButtonColour}
                          >
                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={version.version}>{version.version}</Box>
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          {
                          (version.isDefault === 1)
                            ? (
                              <Typography
                                component="span"
                                sx={{ paddingLeft: '1em', fontWeight: 'bold' }}
                              >
                                Default
                              </Typography>
                            )
                            : null
                          }
                          { InactiveChip(version.isActive) }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            component="span"
                            sx={{
                              fontSize: 'small',
                              paddingLeft: '1em',
                            }}
                          >
                            (
                            {`Modified: ${beautifyDate(version.dateLastModified)}`}
                            )
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  {
                  (noVisibleVersions)
                    ? (
                      <Grid item xs={12}>
                        <Typography sx={{ padding: '5px 0px 5px 0px', fontStyle: 'italic' }}>No versions currently visible</Typography>
                      </Grid>
                    )
                    : null
                  }
                </Grid>
              </Paper>
            );
          })
            : (
              <Box sx={{ width: '100%' }} align="center">
                <Box
                  sx={{
                    marginTop: '10%',
                    padding: '20px',
                    border: 'solid lightgray 1px',
                    width: '80%',
                  }}
                  align="center"
                >
                  <Typography color="#606060">
                    No workflows to show
                  </Typography>
                </Box>
              </Box>
            )}
        </Stack>
      </Grid>
    </Box>
  );
});

export default Workflows;
