import {
  makeObservable, observable, computed, action,
} from 'mobx';

import { isValidJSON, parseActions } from '../../utils/helpers';

class WorkflowVersion {
  workflowId = null;

  versionId = null;

  version = '';

  isActive = null;

  dateLastModified = '';

  whoModifiedLast = '';

  isDefault = false;

  actions = [];

  inputValues = {};

  constructor(data) {
    makeObservable(this, {
      workflowId: observable,
      versionId: observable,
      version: observable,
      isActive: observable,
      dateLastModified: observable,
      whoModifiedLast: observable,
      isDefault: observable,
      actions: observable,
      inputValues: observable,
      setInputValues: action,
      params: computed,
    });
    this.workflowId = data.workflowId;
    this.versionId = data.versionId;
    this.version = data.version;
    this.isActive = data.isActive;
    this.dateLastModified = data.dateLastModified;
    this.whoModifiedLast = data.whoModifiedLast;

    this.isDefault = data.isDefault;
    if (isValidJSON(data.actions)) this.actions = JSON.parse(data.actions);
  }

  setInputValues(name = '', value = undefined) {
    if (name.match('^[@][A-Za-z0-9]*(?:_[A-Za-z0-9]+)*$')) {
      this.inputValues[name] = value;
    }
  }

  get params() {
    const inputs = new Set();
    const users = {};
    if (typeof (this.actions) === 'object') {
      parseActions(this.actions, inputs, users);
    }
    return { inputs: Array.from(inputs), users };
  }
}

export default WorkflowVersion;
