import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Snackbar,
} from '@mui/material';

function AlertNotification(props) {
  const {
    text, open, onClose, type, closeTime,
  } = props;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={closeTime}
      onClose={onClose}
    >
      <Alert
        variant="filled"
        severity={type}
      >
        {text}
      </Alert>

    </Snackbar>
  );
}
AlertNotification.propTypes = {
  text: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  type: PropTypes.string,
  closeTime: PropTypes.number,
};

AlertNotification.defaultProps = {
  text: '',
  type: 'success',
  onClose: () => {},
  closeTime: 5000,
};
export default AlertNotification;
